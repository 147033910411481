import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { ROUTING_PATH } from '@shared/constants/routingPath';
import { QuarterCloseAdjustmentsService } from 'src/app/pages/closing-page/tabs/quarter-close-adjustments/quarter-close-adjustments.service';

@Component({
  selector: 'aux-change-order-approved-dialog',
  templateUrl: './change-order-approved-dialog.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderApprovedDialogComponent {
  co_organization_id: string | undefined;

  forecastMethadologyRoute = `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`;

  timelineRoute = `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX}`;

  adjustmentsRoute = `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`;

  constructor(
    public ref: CustomOverlayRef<unknown, { vendor_id?: string }>,
    private quarterCloseAdjustmentsService: QuarterCloseAdjustmentsService,
    private router: Router
  ) {
    if (ref.data && ref.data.vendor_id) {
      this.co_organization_id = this.ref.data?.vendor_id;
    }
  }

  goFM() {
    if (this.co_organization_id) {
      localStorage.setItem('forecastVendor', this.co_organization_id);
    }
    this.router.navigateByUrl(this.forecastMethadologyRoute);
    this.ref.close();
  }

  goTimeline() {
    this.router.navigateByUrl(this.timelineRoute);
    this.ref.close();
  }

  goAdjustments() {
    this.router.navigateByUrl(this.adjustmentsRoute);
    this.quarterCloseAdjustmentsService.updateFormControlValues(undefined, this.co_organization_id);
    this.ref.close();
  }
}
