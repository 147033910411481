import { timeStampToClocks, isExperimentalFeatureEnabled, ExperimentalFeature, assign, createContextManager, deepClone, makePublicApi, monitor, clocksNow, callMonitored, createHandlingStack, checkUser, sanitizeUser, sanitize, createIdentityEncoder, createCustomerDataTrackerManager, storeContextManager, displayAlreadyInitializedError, createTrackingConsentState, } from '@datadog/browser-core';
import { buildCommonContext } from '../domain/contexts/commonContext';
import { createPreStartStrategy } from './preStartRum';
var RUM_STORAGE_KEY = 'rum';
export function makeRumPublicApi(startRumImpl, recorderApi, options) {
    if (options === void 0) { options = {}; }
    var customerDataTrackerManager = createCustomerDataTrackerManager(0 /* CustomerDataCompressionStatus.Unknown */);
    var globalContextManager = createContextManager(customerDataTrackerManager.getOrCreateTracker(2 /* CustomerDataType.GlobalContext */));
    var userContextManager = createContextManager(customerDataTrackerManager.getOrCreateTracker(1 /* CustomerDataType.User */));
    var trackingConsentState = createTrackingConsentState();
    function getCommonContext() {
        return buildCommonContext(globalContextManager, userContextManager, recorderApi);
    }
    var strategy = createPreStartStrategy(options, getCommonContext, trackingConsentState, function (initConfiguration, configuration, deflateWorker, initialViewOptions) {
        if (isExperimentalFeatureEnabled(ExperimentalFeature.CUSTOM_VITALS)) {
            /**
             * Start a custom duration vital
             * stored in @vital.custom.<name>
             *
             * @param name name of the custom vital
             * @param options.context custom context attached to the vital
             * @param options.startTime epoch timestamp of the start of the custom vital (if not set, will use current time)
             */
            ;
            rumPublicApi.startDurationVital = monitor(function (name, options) {
                strategy.startDurationVital({
                    name: sanitize(name),
                    startClocks: (options === null || options === void 0 ? void 0 : options.startTime) ? timeStampToClocks(options.startTime) : clocksNow(),
                    context: sanitize(options === null || options === void 0 ? void 0 : options.context),
                });
            });
            rumPublicApi.stopDurationVital = monitor(function (name, options) {
                strategy.stopDurationVital({
                    name: sanitize(name),
                    stopClocks: (options === null || options === void 0 ? void 0 : options.stopTime) ? timeStampToClocks(options.stopTime) : clocksNow(),
                    context: sanitize(options === null || options === void 0 ? void 0 : options.context),
                });
            });
        }
        if (initConfiguration.storeContextsAcrossPages) {
            storeContextManager(configuration, globalContextManager, RUM_STORAGE_KEY, 2 /* CustomerDataType.GlobalContext */);
            storeContextManager(configuration, userContextManager, RUM_STORAGE_KEY, 1 /* CustomerDataType.User */);
        }
        customerDataTrackerManager.setCompressionStatus(deflateWorker ? 1 /* CustomerDataCompressionStatus.Enabled */ : 2 /* CustomerDataCompressionStatus.Disabled */);
        var startRumResult = startRumImpl(initConfiguration, configuration, recorderApi, customerDataTrackerManager, getCommonContext, initialViewOptions, deflateWorker && options.createDeflateEncoder
            ? function (streamId) { return options.createDeflateEncoder(configuration, deflateWorker, streamId); }
            : createIdentityEncoder, trackingConsentState);
        recorderApi.onRumStart(startRumResult.lifeCycle, configuration, startRumResult.session, startRumResult.viewContexts, deflateWorker);
        strategy = createPostStartStrategy(initConfiguration, startRumResult);
        return startRumResult;
    });
    var startView = monitor(function (options) {
        var sanitizedOptions = typeof options === 'object' ? options : { name: options };
        strategy.startView(sanitizedOptions);
    });
    var rumPublicApi = makePublicApi({
        init: monitor(function (initConfiguration) { return strategy.init(initConfiguration); }),
        /**
         * Set the tracking consent of the current user.
         *
         * @param {"granted" | "not-granted"} trackingConsent The user tracking consent
         *
         * Data will be sent only if it is set to "granted". This value won't be stored by the library
         * across page loads: you will need to call this method or set the appropriate `trackingConsent`
         * field in the init() method at each page load.
         *
         * If this method is called before the init() method, the provided value will take precedence
         * over the one provided as initialization parameter.
         */
        setTrackingConsent: monitor(function (trackingConsent) { return trackingConsentState.update(trackingConsent); }),
        setGlobalContextProperty: monitor(function (key, value) { return globalContextManager.setContextProperty(key, value); }),
        removeGlobalContextProperty: monitor(function (key) { return globalContextManager.removeContextProperty(key); }),
        getGlobalContext: monitor(function () { return globalContextManager.getContext(); }),
        setGlobalContext: monitor(function (context) { return globalContextManager.setContext(context); }),
        clearGlobalContext: monitor(function () { return globalContextManager.clearContext(); }),
        getInternalContext: monitor(function (startTime) { return strategy.getInternalContext(startTime); }),
        getInitConfiguration: monitor(function () { return deepClone(strategy.initConfiguration); }),
        addAction: monitor(function (name, context) {
            strategy.addAction({
                name: sanitize(name),
                context: sanitize(context),
                startClocks: clocksNow(),
                type: "custom" /* ActionType.CUSTOM */,
            });
        }),
        addError: function (error, context) {
            var handlingStack = createHandlingStack();
            callMonitored(function () {
                strategy.addError({
                    error: error, // Do not sanitize error here, it is needed unserialized by computeRawError()
                    handlingStack: handlingStack,
                    context: sanitize(context),
                    startClocks: clocksNow(),
                });
            });
        },
        /**
         * Add a custom timing relative to the start of the current view,
         * stored in @view.custom_timings.<timing_name>
         *
         * @param name name of the custom timing
         * @param [time] epoch timestamp of the custom timing (if not set, will use current time)
         *
         * Note: passing a relative time is discouraged since it is actually used as-is but displayed relative to the view start.
         * We currently don't provide a way to retrieve the view start time, so it can be challenging to provide a timing relative to the view start.
         * see https://github.com/DataDog/browser-sdk/issues/2552
         */
        addTiming: monitor(function (name, time) {
            // TODO: next major decide to drop relative time support or update its behaviour
            strategy.addTiming(sanitize(name), time);
        }),
        setUser: monitor(function (newUser) {
            if (checkUser(newUser)) {
                userContextManager.setContext(sanitizeUser(newUser));
            }
        }),
        getUser: monitor(function () { return userContextManager.getContext(); }),
        setUserProperty: monitor(function (key, property) {
            var _a;
            var sanitizedProperty = sanitizeUser((_a = {}, _a[key] = property, _a))[key];
            userContextManager.setContextProperty(key, sanitizedProperty);
        }),
        removeUserProperty: monitor(function (key) { return userContextManager.removeContextProperty(key); }),
        clearUser: monitor(function () { return userContextManager.clearContext(); }),
        startView: startView,
        stopSession: monitor(function () {
            strategy.stopSession();
        }),
        /**
         * This feature is currently in beta. For more information see the full [feature flag tracking guide](https://docs.datadoghq.com/real_user_monitoring/feature_flag_tracking/).
         */
        addFeatureFlagEvaluation: monitor(function (key, value) {
            strategy.addFeatureFlagEvaluation(sanitize(key), sanitize(value));
        }),
        getSessionReplayLink: monitor(function () { return recorderApi.getSessionReplayLink(); }),
        startSessionReplayRecording: monitor(function () { return recorderApi.start(); }),
        stopSessionReplayRecording: monitor(function () { return recorderApi.stop(); }),
    });
    return rumPublicApi;
}
function createPostStartStrategy(initConfiguration, startRumResult) {
    return assign({
        init: function (initConfiguration) {
            displayAlreadyInitializedError('DD_RUM', initConfiguration);
        },
        initConfiguration: initConfiguration,
    }, startRumResult);
}
