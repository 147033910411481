import {
  IconAlertTriangle,
  IconArrowRight,
  IconArrowLeft,
  IconCircleArrowDownFilled,
  IconCircleArrowUpFilled,
  IconCircleArrowDown,
  IconCircleArrowUp,
  IconCircleArrowLeft,
  IconCheck,
  IconCircleCheckFilled,
  IconChevronRight,
  IconChevronLeft,
  IconChevronDown,
  IconChevronUp,
  IconChevronsDown,
  IconChevronsUp,
  IconEdit,
  IconEye,
  IconEyeOff,
  IconDownload,
  IconUpload,
  IconTrash,
  IconBooks,
  IconCalendarMinus,
  IconCameraPlus,
  IconCash,
  IconChartBar,
  IconChartBarOff,
  IconChartDots,
  IconCirclePlus,
  IconHelp,
  IconCloudDownload,
  IconCloudUpload,
  IconSettings,
  IconSettingsFilled,
  IconFiles,
  IconCopy,
  IconCalendar,
  IconDeviceFloppy,
  IconFileBroken,
  IconFileCheck,
  IconFileDescription,
  IconFileDownload,
  IconPencil,
  IconFileExport,
  IconFileTypeCsv,
  IconInfoCircle,
  IconLoader,
  IconLock,
  IconLockOpen,
  IconMessage,
  IconFileSearch,
  IconRocket,
  IconSearch,
  IconCheckbox,
  IconTrashOff,
  IconX,
  IconPlus,
  IconBuilding,
  IconFileMinus,
  IconUsers,
  IconBriefcase,
  IconReplace,
  IconUser,
  IconMenu2,
  IconDotsVertical,
  IconAlertCircleFilled,
  IconSparkles,
  IconChartAreaLine,
  IconCalendarPlus,
  IconFolderDollar,
  IconHierarchy3,
  IconCaretDownFilled,
  IconSend,
  IconSettingsBolt,
  IconBox,
  IconRobotFace,
} from 'angular-tabler-icons/icons';

import { IconAuxLogo } from '@components/icon/custom-icons/aux-logo.icon';

const CUSTOM_ICONS = {
  IconAuxLogo,
};

export const AUX_ICONS = {
  IconAlertTriangle,
  IconArrowRight,
  IconArrowLeft,
  IconCircleArrowDownFilled,
  IconCircleArrowUpFilled,
  IconCircleArrowDown,
  IconCircleArrowUp,
  IconCircleArrowLeft,
  IconCheck,
  IconChevronRight,
  IconChevronLeft,
  IconChevronDown,
  IconChevronUp,
  IconChevronsDown,
  IconChevronsUp,
  IconEdit,
  IconEye,
  IconEyeOff,
  IconDownload,
  IconUpload,
  IconTrash,
  IconTrashOff,
  IconBooks,
  IconCalendarMinus,
  IconCalendarPlus,
  IconCameraPlus,
  IconCash,
  IconChartBar,
  IconChartBarOff,
  IconChartDots,
  IconCirclePlus,
  IconCaretDownFilled,
  IconHelp,
  IconHierarchy3,
  IconCloudDownload,
  IconCloudUpload,
  IconSettings,
  IconSettingsFilled,
  IconFiles,
  IconCopy,
  IconCalendar,
  IconDeviceFloppy,
  IconFileBroken,
  IconFileCheck,
  IconFileDescription,
  IconFileDownload,
  IconFileExport,
  IconPencil,
  IconFileTypeCsv,
  IconInfoCircle,
  IconLoader,
  IconLock,
  IconLockOpen,
  IconMessage,
  IconFileSearch,
  IconRocket,
  IconSearch,
  IconCheckbox,
  IconX,
  IconPlus,
  IconCircleCheckFilled,
  IconBuilding,
  IconFileMinus,
  IconUsers,
  IconBriefcase,
  IconReplace,
  IconUser,
  IconMenu2,
  IconDotsVertical,
  IconAlertCircleFilled,
  IconSparkles,
  IconChartAreaLine,
  IconFolderDollar,
  IconSend,
  IconSettingsBolt,
  IconBox,
  IconRobotFace,
  ...CUSTOM_ICONS,
};

type RawIconKeys = keyof typeof AUX_ICONS;
export type AuxIconName<T extends RawIconKeys = RawIconKeys> = T extends `Icon${infer Prefix}`
  ? Prefix
  : never;
