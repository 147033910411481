import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  Type,
  TemplateRef,
} from '@angular/core';
import { CdkPortalOutlet, PortalOutlet } from '@angular/cdk/portal';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CustomOverlayRef } from './custom-overlay-ref';
import {
  NgClass,
  NgComponentOutlet,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';
import { IconComponent } from '@components/icon/icon.component';
import { MODAL_ANIMATIONS } from '@shared/animations/modal.animation';

@UntilDestroy()
@Component({
  selector: 'aux-overlay',
  templateUrl: './overlay.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: MODAL_ANIMATIONS,
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgComponentOutlet,
    NgTemplateOutlet,
    IconComponent,
    NgClass,
  ],
})
export class OverlayComponent implements OnInit {
  @ViewChild(CdkPortalOutlet, { static: false }) portalOutlet: PortalOutlet | undefined = undefined;

  contentType: 'template' | 'string' | 'component' | undefined;

  headerType: 'custom' | 'default' = 'default';

  content: string | TemplateRef<unknown> | Type<unknown> | null = null;

  context: null | { [k: string]: unknown } = null;

  customHeader: Type<unknown> | null = null;

  inlineBudget = false;

  closeButton = true;

  constructor(
    public ref: CustomOverlayRef<
      unknown,
      {
        customHeader: Type<unknown>;
        displayX: boolean;
        useDesignSystemStyling: boolean;
        header: string;
        headerClass?: string;
        inlineBudget?: boolean;
      }
    >
  ) {
    if (this.ref.data?.customHeader) {
      this.customHeader = this.ref.data.customHeader;
      this.headerType = 'custom';
    }
  }

  ngOnInit() {
    this.content = this.ref.content || null;
    this.closeButton = this.ref.closeButton;
    this.inlineBudget = this.ref.data?.inlineBudget || false;
    if (typeof this.content === 'string') {
      this.contentType = 'string';
    } else if (this.content instanceof TemplateRef) {
      this.contentType = 'template';
      this.context = {
        close: this.ref.close.bind(this.ref),
      };
    } else {
      this.contentType = 'component';
    }
  }

  close() {
    if (this.closeButton) {
      this.ref.close();
    }
  }
}
