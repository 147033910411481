import { StorageAction } from '@aws-amplify/core/internals/utils';
import '@smithy/md5-js';
import '@aws-amplify/core/internals/aws-client-utils';
import '../../utils/client/runtime/s3TransferHandler/fetch.mjs';
import 'fast-xml-parser';
import '../../utils/client/runtime/s3TransferHandler/xhr.mjs';
import 'buffer';
import { resolveS3ConfigAndInput } from '../../utils/resolveS3ConfigAndInput.mjs';
import '../../../../errors/types/validation.mjs';
import { logger } from '../../../../utils/logger.mjs';
import '../../utils/client/base.mjs';
import '../../utils/client/getObject.mjs';
import '../../utils/client/listObjectsV2.mjs';
import '../../utils/client/putObject.mjs';
import '../../utils/client/createMultipartUpload.mjs';
import '../../utils/client/uploadPart.mjs';
import '../../utils/client/completeMultipartUpload.mjs';
import '../../utils/client/listParts.mjs';
import '../../utils/client/abortMultipartUpload.mjs';
import '../../utils/client/copyObject.mjs';
import '../../utils/client/headObject.mjs';
import { deleteObject } from '../../utils/client/deleteObject.mjs';
import { getStorageUserAgentValue } from '../../utils/userAgent.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const remove = async (amplify, input) => {
    const { key, options = {} } = input;
    const { s3Config, keyPrefix, bucket } = await resolveS3ConfigAndInput(amplify, options);
    const finalKey = `${keyPrefix}${key}`;
    logger.debug(`remove "${key}" from "${finalKey}".`);
    await deleteObject({
        ...s3Config,
        userAgentValue: getStorageUserAgentValue(StorageAction.Remove),
    }, {
        Bucket: bucket,
        Key: finalKey,
    });
    return {
        key,
    };
};

export { remove };

