import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import { FormGroupDirective, NgControl } from '@angular/forms';
import dayjs from 'dayjs';
import { BehaviorSubject, EMPTY, merge } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { get } from 'lodash-es';
import { FormControlConstants } from '@constants/form-control.constants';

@UntilDestroy()
@Directive({
  selector: '[auxFormError]',
  standalone: true,
})
export class FormErrorDirective implements OnInit, AfterViewInit {
  readonly formControlConstants = FormControlConstants;

  onBlur$ = new EventEmitter();

  showErrors = new BehaviorSubject(false);

  errorDiv: HTMLDivElement | undefined;

  isInvalid = new BehaviorSubject(false);

  isValid = new BehaviorSubject(false);

  @Input() label = '';

  @Input() labelForErrorMessage = '';

  @Input() fieldName = '';

  @Input() min = '';

  @Input() showErrorMessage = true;
  @Input() customErrorMessages: {
    [k: string]: string | ((fc: NgControl, error: unknown) => string);
  } = {};
  errorMessages: { [k: string]: string } = {
    required: this.formControlConstants.VALIDATION_MESSAGE.REQUIRED,
    greaterThanZero: this.formControlConstants.VALIDATION_MESSAGE.GREATER_THEN_ZERO,
    mustHaveLowercaseChar: this.formControlConstants.VALIDATION_MESSAGE.MUST_HAVE_LOWERCASE_CHAR,
    mustHaveUppercaseChar: this.formControlConstants.VALIDATION_MESSAGE.MUST_HAVE_UPPERCASE_CHAR,
    mustHaveNumberChar: this.formControlConstants.VALIDATION_MESSAGE.MUST_HAVE_NUMBER_CHAR,
    minlength: this.formControlConstants.VALIDATION_MESSAGE.MIN_LENGTH,
    email: this.formControlConstants.VALIDATION_MESSAGE.EMAIL,
    date: this.formControlConstants.VALIDATION_MESSAGE.DATE,
    month_min: this.formControlConstants.VALIDATION_MESSAGE.MONTH_MIN,
    month_max: this.formControlConstants.VALIDATION_MESSAGE.MONTH_MAX,
    trialTimelinePeriod: this.formControlConstants.VALIDATION_MESSAGE.TRIAL_TIMELINE_PERIOD,
    emptyVendors: this.formControlConstants.VALIDATION_MESSAGE.EMPTY_VENDORS,
    duplicatedSite: this.formControlConstants.VALIDATION_MESSAGE.DUPLICATED_SITE,
    duplicatedChangeOrder: this.formControlConstants.VALIDATION_MESSAGE.DUPLICATED_CHANGE_ORDER,
    duplicatedVendors: this.formControlConstants.VALIDATION_MESSAGE.DUPLICATED_VENDORS,
    duplicatedMilestoneNames:
      this.formControlConstants.VALIDATION_MESSAGE.DUPLICATED_MILESTONE_NAMES,
    milestonesTrackEachOther:
      this.formControlConstants.VALIDATION_MESSAGE.MILESTONES_TRACK_EACH_OTHER,
    site_closeout: this.formControlConstants.VALIDATION_MESSAGE.SITE_CLOSEOUT,
    duplicate_version: this.formControlConstants.VALIDATION_MESSAGE.DUPLICATE_VERSION_NAME,
    duplicate_site_budget: this.formControlConstants.VALIDATION_MESSAGE.DUPLICATE_SITE_BUDGET,
    incorrect_site_budget_effective_date:
      this.formControlConstants.VALIDATION_MESSAGE.INCORRECT_SITE_BUDGET_EFFECTIVE_DATE,
  };

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private fc: NgControl,
    // @Optional() private fc?: FormControlName,
    @Optional() private fg?: FormGroupDirective
  ) {
    this.showErrors.pipe(untilDestroyed(this)).subscribe((showErrors) => {
      if (showErrors && this.errorDiv) {
        this.removeErrorDiv();
        this.createErrorDiv();
        if (this.fc.errors) {
          this.renderer.addClass(this.errorDiv, 'text-aux-error');
          this.renderer.addClass(this.errorDiv, 'pl-3');
          this.renderer.addClass(this.errorDiv, 'text-sm');
          this.renderer.addClass(this.errorDiv, 'mt-0.5');
          for (const [key, val] of Object.entries(this.fc.errors)) {
            if (val) {
              let errorMessage =
                { ...this.errorMessages, ...this.customErrorMessages }[key] ?? `${key} error`;

              errorMessage =
                typeof errorMessage === 'function' ? errorMessage(this.fc, val) : errorMessage;

              if (key === 'date') {
                // Use the Input min date in the error message
                // instead of 01/01/2000 (still falls back to 01/01/2000).
                errorMessage = this.createCustomDateErrorMessage(errorMessage);
              }

              if (errorMessage) {
                const div = this.renderer.createElement('div');
                div.innerHTML = this.parse(errorMessage, {
                  ...this.fc.errors,
                  label: this.fieldName || this.label || this.labelForErrorMessage || 'Field',
                });
                if (this.showErrorMessage) {
                  this.renderer.appendChild(this.errorDiv, div);
                }
              }
            }
          }
        }
      } else if (this.errorDiv) {
        this.removeErrorDiv();
      }
    });
  }

  protected _elementClass: string[] = [];

  @Input('class')
  @HostBinding('class')
  get elementClass(): string {
    return this._elementClass.join(' ');
  }

  set elementClass(val: string) {
    this._elementClass = val.split(' ');
  }

  parse(text: string, obj: { [k: string]: string } = {}) {
    return text.replace(/{{(.*?)}}/g, (_: string, firstMatch: string) => {
      try {
        return get(obj, firstMatch) || '';
      } catch (e) {
        return '';
      }
    });
  }

  removeErrorDiv(): void {
    this.renderer.removeChild(this.el.nativeElement.parentNode, this.errorDiv);
  }

  createErrorDiv() {
    this.errorDiv = this.renderer.createElement('div');
    this.renderer.appendChild(this.el.nativeElement.parentNode, this.errorDiv);
  }

  @HostListener('blur') onBlur() {
    this.onBlur$.emit();
  }

  ngOnInit(): void {
    merge(
      this.fg ? this.fg.ngSubmit.asObservable() : EMPTY,
      this.fc.statusChanges || EMPTY,
      this.onBlur$
    )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isInvalid.next(
          !!((this.fg?.submitted || this.fc.control?.touched) && this.fc.control?.errors)
        );
        this.isValid.next(!!this.fc.control?.touched && !this.fc.control?.errors);

        this.showErrors.next(!!(this.fg?.submitted || this.fc.control?.touched));

        const classesObj = {
          'is-invalid': this.isInvalid.getValue(),
          'is-valid': this.isValid.getValue(),
        };

        const classes = this._elementClass.filter((x) => x !== 'is-invalid' && x !== 'is-valid');
        for (const [key, value] of Object.entries(classesObj)) {
          if (value) {
            classes.push(key);
          }
        }
        this.elementClass = classes.join(' ');
      });
  }

  ngAfterViewInit(): void {
    this.createErrorDiv();
    this.showErrors.next(this.showErrors.getValue());

    if (!this.label) {
      const input = this.el.nativeElement as HTMLInputElement;
      if (input.labels?.length) {
        this.label = input.labels[0].textContent || '';
      }
    }
  }

  // The aux-input component only creates a 'date' error
  // when validating min dates.
  // Max dates use the month_max error instead, so
  // this function should currently only be used for min dates
  createCustomDateErrorMessage(errorMessage: string): string {
    if (!this.min) {
      return errorMessage;
    }

    const minDate = dayjs(this.min);

    if (!minDate.isValid()) {
      return errorMessage;
    }

    const currentErrorMessage = this.formControlConstants.VALIDATION_MESSAGE.DATE;
    const currentErrorDate = '01/01/2000';
    const minDateFormatted = minDate.format('MM/DD/YYYY');

    return currentErrorMessage.replace(currentErrorDate, minDateFormatted);
  }
}
