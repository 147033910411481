// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var StorageValidationErrorCode;
(function (StorageValidationErrorCode) {
    StorageValidationErrorCode["NoCredentials"] = "NoCredentials";
    StorageValidationErrorCode["NoIdentityId"] = "NoIdentityId";
    StorageValidationErrorCode["NoKey"] = "NoKey";
    StorageValidationErrorCode["NoSourceKey"] = "NoSourceKey";
    StorageValidationErrorCode["NoDestinationKey"] = "NoDestinationKey";
    StorageValidationErrorCode["NoBucket"] = "NoBucket";
    StorageValidationErrorCode["NoRegion"] = "NoRegion";
    StorageValidationErrorCode["UrlExpirationMaxLimitExceed"] = "UrlExpirationMaxLimitExceed";
    StorageValidationErrorCode["ObjectIsTooLarge"] = "ObjectIsTooLarge";
    StorageValidationErrorCode["InvalidUploadSource"] = "InvalidUploadSource";
})(StorageValidationErrorCode || (StorageValidationErrorCode = {}));
const validationErrorMap = {
    [StorageValidationErrorCode.NoCredentials]: {
        message: 'Credentials should not be empty.',
    },
    [StorageValidationErrorCode.NoIdentityId]: {
        message: 'Missing identity ID when accessing objects in protected or private access level.',
    },
    [StorageValidationErrorCode.NoKey]: {
        message: 'Missing key in api call.',
    },
    [StorageValidationErrorCode.NoSourceKey]: {
        message: 'Missing source key in copy api call.',
    },
    [StorageValidationErrorCode.NoDestinationKey]: {
        message: 'Missing destination key in copy api call.',
    },
    [StorageValidationErrorCode.NoBucket]: {
        message: 'Missing bucket name while accessing object.',
    },
    [StorageValidationErrorCode.NoRegion]: {
        message: 'Missing region while accessing object.',
    },
    [StorageValidationErrorCode.UrlExpirationMaxLimitExceed]: {
        message: 'Url Expiration can not be greater than 7 Days.',
    },
    [StorageValidationErrorCode.ObjectIsTooLarge]: {
        message: 'Object size cannot not be greater than 5TB.',
    },
    [StorageValidationErrorCode.InvalidUploadSource]: {
        message: 'Upload source type can only be a `Blob`, `File`, `ArrayBuffer`, or `string`.',
    },
};

export { StorageValidationErrorCode, validationErrorMap };

