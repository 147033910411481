import { parseMetadata } from '@aws-amplify/core/internals/aws-client-utils';
import { AmplifyUrl, AmplifyUrlSearchParams } from '@aws-amplify/core/internals/utils';
import { composeServiceApi } from '@aws-amplify/core/internals/aws-client-utils/composers';
import { defaultConfig } from './base.mjs';
import { parseXmlError } from './utils/parsePayload.mjs';
import { s3TransferHandler } from './runtime/s3TransferHandler/fetch.mjs';
import 'fast-xml-parser';
import './runtime/s3TransferHandler/xhr.mjs';
import 'buffer';
import { buildStorageServiceError, map } from './utils/deserializeHelpers.mjs';
import { assignStringVariables, validateS3RequiredParameter, serializePathnameObjectKey } from './utils/serializeHelpers.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const uploadPartSerializer = async (input, endpoint) => {
    const headers = {
        ...assignStringVariables({ 'content-md5': input.ContentMD5 }),
    };
    headers['content-type'] = 'application/octet-stream';
    const url = new AmplifyUrl(endpoint.url.toString());
    validateS3RequiredParameter(!!input.Key, 'Key');
    url.pathname = serializePathnameObjectKey(url, input.Key);
    validateS3RequiredParameter(!!input.PartNumber, 'PartNumber');
    validateS3RequiredParameter(!!input.UploadId, 'UploadId');
    url.search = new AmplifyUrlSearchParams({
        partNumber: input.PartNumber + '',
        uploadId: input.UploadId,
    }).toString();
    return {
        method: 'PUT',
        headers,
        url,
        body: input.Body,
    };
};
const uploadPartDeserializer = async (response) => {
    if (response.statusCode >= 300) {
        const error = (await parseXmlError(response));
        throw buildStorageServiceError(error, response.statusCode);
    }
    else {
        return {
            ...map(response.headers, {
                ETag: 'etag',
            }),
            $metadata: parseMetadata(response),
        };
    }
};
const uploadPart = composeServiceApi(s3TransferHandler, uploadPartSerializer, uploadPartDeserializer, { ...defaultConfig, responseType: 'text' });

export { uploadPart };

