import { Column, GridApi, IRowNode } from '@ag-grid-community/core';

export function AgEditFirstRow({
  gridApi,
  filterNodes = (nodes) => nodes,
  passSpecifiedColumns = (columns) => columns,
}: {
  gridApi: GridApi;
  filterNodes?: (nodes: IRowNode[]) => IRowNode[];
  passSpecifiedColumns?: (columns: Column[]) => Column[];
}): boolean {
  const nodes = filterNodes(gridApi.getRenderedNodes());
  const passedColumns = passSpecifiedColumns(gridApi.getAllDisplayedColumns());
  const openNodes = nodes.filter((z) => !z.group);

  if (openNodes.length) {
    for (const node of openNodes) {
      const cell = AgFindTheFirstEditableCell({
        node,
        gridApi,
        passedColumns,
      });
      if (cell) {
        gridApi.startEditingCell({
          colKey: cell.colKey,
          rowIndex: cell.rowIndex,
        });
        return true;
      }
    }
  } else if (nodes.filter((z) => !!z.group).length) {
    AgExpandAllRows({ gridApi });
    return AgEditFirstRow({
      gridApi,
      filterNodes,
    });
  }
  return false;
}

// after this method call gridApi.onGroupExpandedOrCollapsed();
export function AgExpandRowRecursively(node: IRowNode) {
  if (node.isExpandable()) {
    node.setExpanded(true);
    if (node.childrenAfterGroup?.length) {
      AgExpandRowRecursively(node.childrenAfterGroup[0]);
    }
  }
}

export function AgExpandAllRows({
  gridApi,
  expanded = true,
}: {
  gridApi: GridApi;
  expanded?: boolean;
}) {
  gridApi.forEachNode((node) => {
    node.expanded = expanded;
  });
  gridApi.onGroupExpandedOrCollapsed();
}

export function AgFindTheFirstEditableCell({
  gridApi,
  node,
  passedColumns,
}: {
  node: IRowNode;
  gridApi: GridApi;
  passedColumns?: Column[];
}) {
  const columns = gridApi.getAllDisplayedColumns();
  if (node.rowIndex == null) {
    return null;
  }
  const passedCols = passedColumns ? passedColumns : ([] as Column[]);
  for (const column of columns) {
    if (
      column.isCellEditable(node) &&
      !passedCols.find((col) => column.getColId() === col.getColId())
    ) {
      return {
        rowIndex: node.rowIndex,
        colKey: column,
      };
    }
  }
  return null;
}

export function AgFindColumnCellById({
  gridApi,
  node,
  colId,
}: {
  node: IRowNode;
  gridApi: GridApi;
  colId: string;
}) {
  const column = gridApi.getAllDisplayedColumns().find((column) => column.getColId() === colId);

  if (node.rowIndex == null || !column) {
    return null;
  }

  if (column.isCellEditable(node)) {
    return {
      rowIndex: node.rowIndex,
      colKey: column,
    };
  }

  return null;
}
