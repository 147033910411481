import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { EditableListDropdownItem } from '@components/editable-list-dropdown/editable-list-dropdown-item.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'aux-editable-list-dropdown',
  templateUrl: './editable-list-dropdown.component.html',
  styleUrls: ['editable-list-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableListDropdownComponent implements OnInit {
  @Input() dropdownFormControl = new FormControl('');

  @Input() items: EditableListDropdownItem[] = [];

  @Input() label = '';

  @Input() id = '';

  @Input() classList = '';

  @Input() createBtnLabel = '';

  @Output() edit = new EventEmitter<EditableListDropdownItem>();

  @Output() delete = new EventEmitter<EditableListDropdownItem>();

  @Output() create = new EventEmitter();

  formControlValue: string | null = '';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.dropdownFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.formControlValue = value || null;
      this.changeDetectorRef.detectChanges();
    });
  }

  onEdit(event: Event, item: EditableListDropdownItem): void {
    event.stopPropagation();
    if (item.disabled) {
      return;
    }

    this.edit.emit(item);
  }

  onDelete(event: Event, item: EditableListDropdownItem): void {
    event.stopPropagation();
    if (item.disabled) {
      return;
    }

    this.delete.emit(item);
  }

  onCreate(): void {
    this.create.emit();
  }

  select(value: string): void {
    this.dropdownFormControl.setValue(value);
  }
}
