import {
  NotificationPage,
  NotificationStatus,
  EventType,
  EntityType,
  AnalyticsCardType,
} from '@services/gql.service';
import { ROUTING_PATH } from '@shared/constants/routingPath';

export interface SubscriptionEvents {
  [NotificationStatus.SUCCESS]: {
    [EventType.ANALYTICS]: object;
    [EventType.ANALYTICS_CARD_UPDATED]: {
      entity_id: string;
      entity_type: EntityType;
      analyticsCardType: AnalyticsCardType;
      analyticsCardData: string;
    };
    [EventType.APPSYNC]: object;
    [EventType.HOUSEKEEPING_TASK]: object;
    [EventType.BALANCE_IN_ESCROW_UPDATED]: object;
    [EventType.CREATE_BUDGET_SNAPSHOT]: object;
    [EventType.CREATE_TRIAL_BUDGET_SNAPSHOT]: object;
    [EventType.BLENDED_PATIENT_CURVE_UPDATED]: object;
    [EventType.BLENDED_SITE_CURVE_UPDATED]: object;
    [EventType.BUDGET_CACHE_INVALIDATED]: object;
    [EventType.BUDGET_MERGED]: object;
    [EventType.BUDGET_FORECAST_SETTINGS_UPDATED]: object;
    [EventType.BUDGET_MONTH_CLOSED]: object;
    [EventType.BUDGET_TEMPLATE_UPLOADED]: object;
    [EventType.BUDGET_SNAPSHOT_NOTIFICATION]: object;
    [EventType.BULK_INVOICE_TEMPLATE_UPLOADED]: object;
    [EventType.BULK_SITE_TEMPLATE_UPLOADED]: object;
    [EventType.CASH_REQUIREMENTS_UPDATED]: object;
    [EventType.CATEGORIZE_INVOICES_REMINDER]: object;
    [EventType.CHANGE_LOG_UPDATED]: object;
    [EventType.CHANGE_ORDER_APPROVED]: object;
    [EventType.CHANGE_ORDER_BACK_TO_PENDING_REVIEW]: object;
    [EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED]: object;
    [EventType.CHANGE_ORDER_DECLINED]: object;
    [EventType.CHANGE_ORDER_DELETED]: object;
    [EventType.CHANGE_ORDER_PENDING_APPROVAL]: object;
    [EventType.CHANGE_ORDER_PENDING_REVIEW]: object;
    [EventType.CLOSE_TRIAL_MONTH]: {
      trial_id: string;
    };
    [EventType.COMPLETE_CHECKLIST_REMINDER]: object;
    [EventType.HOUSEKEEPING_TASK]: object;
    [EventType.CREATE_BUDGET_SNAPSHOT]: object;
    [EventType.CREATE_MONTH_CLOSE_LOCK]: object;
    [EventType.CREATE_TRIAL_BUDGET_SNAPSHOT]: object;
    [EventType.CREATE_TRIAL_SETTINGS]: object;
    [EventType.CREATE_CLOSE_TRIAL_MONTH_REPORTS]: object;
    [EventType.DOCUMENT_LIBRARY_MIGRATION]: object;
    [EventType.DOCUMENT_UPLOADED_NOTIFICATION]: object;
    [EventType.DOWNLOAD_EXCHANGE_RATES]: object;
    [EventType.ENVIRONMENT_BUILT]: object;
    [EventType.ERROR]: object;
    [EventType.GENERATE_EXPORT]: object;
    [EventType.INLINE_EDIT_BUDGET_ACTIVITIES]: object;
    [EventType.INLINE_EDIT_BUDGET_CATEGORIES]: object;
    [EventType.INVOICE_CREATED]: object;
    [EventType.INVOICE_DOCUMENT_UPLOADED]: object;
    [EventType.INVOICE_PENDING_APPROVAL]: object;
    [EventType.INVOICE_PENDING_REVIEW]: object;
    [EventType.INVOICE_TEMPLATE_UPLOADED]: object;
    [EventType.INVOICE_UPDATED]: object;
    [EventType.INVOICE_UPLOADED]: object;
    [EventType.MONTH_AVAILABLE_TO_CLOSE]: object;
    [EventType.NETSUITE_CLOSE_MONTH_JOURNAL_ENTRY]: object;
    [EventType.NEW_TASK]: object;
    [EventType.NUMBER_OF_PENDING_APPROVAL_COS_UPDATED]: {
      trial_id: string;
    };
    [EventType.PATIENT_BUDGET_TEMPLATE_UPLOADED]: object;
    [EventType.PATIENT_DRIVER_DISTRIBUTION_UPDATED]: object;
    [EventType.PATIENT_DRIVER_TEMPLATE_UPLOADED]: object;
    [EventType.PAYMENT_MILESTONE_UPDATED]: object;
    [EventType.PO_REPORT_UPLOADED]: {
      trial_id: string;
    };
    [EventType.QUARTER_CLOSED]: object;
    [EventType.RECALCULATE_MONTHLY_AUDIT_PACKAGE]: object;
    [EventType.REFRESH_BILL_COM]: {
      trial_id: string;
    };
    [EventType.REFRESH_BUDGET]: {
      vendor_id: string;
    };
    [EventType.REFRESH_COUPA]: {
      trial_id: string;
    };
    [EventType.REFRESH_DYNAMICS365]: {
      trial_id: string;
    };
    [EventType.REFRESH_DYNAMICS365_FO]: {
      trial_id: string;
    };
    [EventType.REFRESH_NETSUITE]: {
      trial_id: string;
    };
    [EventType.REFRESH_ORACLE_FUSION]: {
      trial_id: string;
    };
    [EventType.REFRESH_PATIENT_BUDGET]: {
      trial_id: string;
    };
    [EventType.REFRESH_QUICKBOOKS_ONLINE]: {
      trial_id: string;
    };
    [EventType.REFRESH_SAGE_INTACCT]: {
      trial_id: string;
    };
    [EventType.REFRESH_SITES]: {
      trial_id: string;
    };
    [EventType.REFRESH_USER_AUDIT_LOG]: {
      trial_id: string;
    };
    [EventType.REFRESH_USER_PERMISSIONS]: {
      trial_id: string;
    };
    [EventType.REFRESH_PORTFOLIO_DASHBOARD]: object;
    [EventType.REQUEST_JOURNAL_ENTRY_REPORT_NOTIFICATION]: object;
    [EventType.SITE_BUDGET_EFFECTIVE_DATE_UPDATED]: object;
    [EventType.SITE_CONTRACT_UPLOADED]: object;
    [EventType.SITE_DRIVER_DISTRIBUTION_UPDATED]: object;
    [EventType.SITE_DRIVER_TEMPLATE_UPLOADED]: object;
    [EventType.SITE_PATIENT_TRACKER_TEMPLATE_UPLOADED]: object;
    [EventType.SITE_PAYMENT_SCHEDULE_TEMPLATE_UPLOADED]: object;
    [EventType.SPECIFICATIONS_IMAGE_UPLOADED]: object;
    [EventType.SPECIFICATIONS_TEMPLATE_UPLOADED]: object;
    [EventType.SPECIFICATIONS_UPDATED]: object;
    [EventType.STORED_DOCUMENT_UPLOADED]: object;
    [EventType.SYNC_USERS_TO_HUBSPOT]: object;
    [EventType.TIMELINE_UPDATED]: object;
    [EventType.TRIAL_PROTOCOL_UPLOADED]: object;
    [EventType.TRIAL_MONTH_CLOSED]: object;
    [EventType.TRIAL_CHANGED]: {
      trial_id: string;
    };
    [EventType.UPLOAD_INVOICE_REMINDER]: object;
    [EventType.UPLOAD_VENDOR_ESTIMATE_REMINDER]: object;
    [EventType.USER_ADDED_TO_TRIAL]: object;
    [EventType.USER_PASSWORD_UPDATED]: object;
    [EventType.VALIDATE_UPLOADED_FILE]: object;
    [EventType.VENDOR_DOCUMENT_UPLOADED_NOTIFICATION]: object;
    [EventType.VENDOR_ESTIMATE_SUPPORTING_DOCUMENT_UPLOADED]: object;
    [EventType.VENDOR_ESTIMATE_TEMPLATE_UPLOADED]: object;
    [EventType.UPDATE_INVESTIGATOR_SCHEDULES]: object;
    [EventType.UPDATE_INVESTIGATOR_TRANSACTIONS]: object;
    [EventType.UPDATE_MONTH_ACCRUALS]: object;
    [EventType.UPDATE_QUARTER_ACCRUALS]: object;
    [EventType.USER_AUDIT_LOG]: object;
    [EventType.USER_AUDIT_LOG_UPDATED]: object;
    [EventType.BUDGET_MERGED]: object;
  };
  [NotificationStatus.ERROR]: {
    [k in EventType]: {
      notification_message?: string;
    };
  };
  [NotificationStatus.EXPORT_READY]: {
    [k in EventType]: {
      notification_message?: string;
      file_path?: string;
    };
  };
}

export const NotificationPages: Record<NotificationPage, string> = {
  [NotificationPage.AUDIT_LOG]: `/${ROUTING_PATH.AUDIT_HISTORY}`,
  [NotificationPage.BUDGET]: `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.INDEX}`,
  [NotificationPage.CHANGE_ORDER]: `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}`,
  [NotificationPage.FORECAST]: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}`,
  [NotificationPage.GLOBAL]: '',
  [NotificationPage.IN_MONTH]: `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`,
  [NotificationPage.IN_MONTH_ADJUSTMENTS]: `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`,
  [NotificationPage.INVESTIGATOR_TRANSACTIONS]: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_TRANSACTIONS}`,
  [NotificationPage.INVOICE]: `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`,
  [NotificationPage.PAYMENT_SCHEDULE]: `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_SCHEDULE}`,
  [NotificationPage.PATIENT_TRACKER]: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_TRACKER}`,
  [NotificationPage.PORTFOLIO_DASHBOARD]: `/${ROUTING_PATH.HOME}`,
  [NotificationPage.QUARTER_CLOSE_CHECKLIST]: `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.CHECKLIST}`,
  [NotificationPage.SITE_CURVE]: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES}`,
  [NotificationPage.SITES]: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES.INDEX}`,
  [NotificationPage.TRIAL_SETTINGS]: `/${ROUTING_PATH.SETTINGS.INDEX}`,
};
