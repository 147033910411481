import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  stage: 'cem',
  restApiKey: 'l8Xp0NjMJv5XfYBLvKnz38dR2unvm8tIbPWJGH37',
  restApiEndpoint: 'https://j9928rh61f.execute-api.us-east-1.amazonaws.com/cem',
  cognito: {
    identityPoolId: 'us-east-1:143384e7-3e43-4524-8eaf-3c2544318292',
    userPoolId: 'us-east-1_yh2cv6v3r',
    userPoolClientId: '9ufcuqdm0l103cr384mjdo4e7',
    loginWith: {
      oauth: {
        domain: 'auxilius-cem-user-pool.auth.us-east-1.amazoncognito.com',
        scopes: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        redirectSignIn: ['http://localhost:4200'],
        redirectSignOut: ['http://localhost:4200'],
        responseType: 'code',
      },
    },
  },
  appSyncApiId: 'rrypuwsitvao5lrwuq7v23zmxe',
  API: {
    endpoint: 'https://nypm6xjlmfabbmgri32ujwrnou.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
    defaultAuthMode: 'userPool',
  },
  S3: {
    bucket: 'cem-auxilius-documents',
    region: 'us-east-1',
  },
  launchDarkly: {
    clientId: '62f41b9d67be3b1153482f1a',
    clientName: 'dev',
  },
  analytics: {
    Pendo: {
      accountId: 'cem-local',
    },
  },
};
