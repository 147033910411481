<section [class]="className" [ngClass]="{ '!bg-aux-gray-dark-2': disabled }">
  <div class="w-full text-center text-aux-gray-dark-100">
    <div #ref><ng-content></ng-content></div>
    <span *ngIf="ref.children.length === 0">
      Drag and drop files here, <br />
      or
      <span
        [ngClass]="{
          'opacity-50': disableUploadLink === true
        }"
        class="aux-link"
        >upload</span
      >
    </span>
  </div>
  <input
    [disabled]="disableUploadLink"
    [auxTooltip]="linkTooltip"
    #uploadInput
    type="file"
    name="file"
    multiple
    class="absolute inset-0 z-10 w-full h-full !opacity-0 cursor-pointer"
    [accept]="accept"
    (change)="addFiles($event)"
    [disabled]="disabled"
  />
</section>
<div *ngIf="hasInvalidFiles" class="text-aux-error text-sm py-2 bg-white">
  Only files with the following extensions are permitted:
  <ng-container *ngIf="showSpecificError; else defaultError">
    {{ extensions.join(', ') }}.
  </ng-container>
  <ng-template #defaultError> csv, doc, docx, jpeg, jpg, pdf, png, xls, or xlsx.</ng-template>
</div>
