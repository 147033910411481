import { ApiService } from '@services/api.service';
import { EventStatus, EventType, GqlService, getEventTrackerQuery } from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { Observable, firstValueFrom } from 'rxjs';

const VALIDATION_POLLING_PERIOD_SECONDS = 5;
const DATA_LOAD_POLLING_PERIOD_SECONDS = 10;
const MAX_RUN_LENGTH_SECONDS = 3600;
const WAKE_UP_PERIOD_MSECS = 50;

enum EtlTaskTrackerState {
  EtlInitial = 0,
  // no tampering with the entries above this line

  InitializeValidationMonitor,
  ValidationInProgress,
  ValidationComplete,
  ValidationFailed,

  InitializeDataLoadMonitor,
  DataLoadInProgress,
  DataLoadComplete,
  DataLoadFailed,

  // no tampering with the entries below this line
  EtlError,
  EtlFinal,
}

export enum EtlPhase {
  Unknown = '',
  Validation = 'validation',
  DataLoad = 'data-load',
}

export interface EtlTaskStatus {
  phase: EtlPhase;
  event_type: EventType;
  bucket_key: string;
  file_signature: string;
  is_in_progress: boolean;
  should_update_progress: boolean;
  percent_complete: number;
  completion_error_message?: string;
  success_notification_message: string;
  error_notification_message: string;
  raw_response: getEventTrackerQuery;
}

export enum EtlSubtaskStatus {
  Pending = '',
  Success = 'success',
  Error = 'error',
}

export interface EtlValidationErrors {
  file_header_errors: string[];
  row_errors: string[];
  file_footer_errors: string[];
}

export interface EtlValidationTaskStatus extends EtlTaskStatus {
  phase: EtlPhase.Validation;
  val_status: EtlSubtaskStatus;
  val_next_tracker_id: string;
  val_error_message: string;
  val_errors: EtlValidationErrors;
  val_percent_complete: number;
  val_num_processed_csv_lines: number;
  val_num_total_csv_lines: number;
}

export interface EtlDataLoadTaskStatus extends EtlTaskStatus {
  phase: EtlPhase.DataLoad;
  // currently the backend stores/returns an empty object.
  // so, for now this definition just represents the future layout!
  dl_status: EtlSubtaskStatus;
  dl_error_message: string;
  dl_percent_complete: number;
  dl_num_processed_csv_lines: number;
  dl_num_total_csv_lines: number;
}

export function consolidateMessages(
  messages: string[],
  options?: { separator?: string; maxLength?: number }
): string {
  if (messages == null) {
    return '';
  } else if (!Array.isArray(messages)) {
    return typeof messages === 'string' ? messages : JSON.stringify(messages);
  }
  const outputbuf: string[] = [];
  //
  const separator =
    typeof options?.separator === 'string' && options.separator ? options.separator : '\n';

  const outputMaxLength =
    Number.isInteger(options?.maxLength) && (options?.maxLength || 0) >= 100
      ? options?.maxLength || 0
      : 20000;

  let idx = 0;
  let outputlen = 0;
  let overflow = false;
  while (idx < messages.length && !overflow) {
    const maxAllowedMessageLength =
      outputMaxLength - outputlen - (outputbuf.length ? separator.length : 0);
    let msg = messages[idx];
    if (msg.length > maxAllowedMessageLength) {
      overflow = true;
      const l = maxAllowedMessageLength - 3;
      msg = l > 0 ? `${msg.substring(0, l)}...` : '';
    }
    if (msg) {
      if (outputbuf.length) {
        outputbuf.push(separator);
        outputlen += separator.length;
      }
      outputbuf.push(msg);
      outputlen += msg.length;
    }
    idx += 1;
  }
  //
  return outputbuf.join('');
}

export function consolidateEtlValidationErrors(etlValidationErrors: EtlValidationErrors): string {
  let allErrors: string[] = [];
  if (Array.isArray(etlValidationErrors?.file_header_errors)) {
    allErrors = allErrors.concat(etlValidationErrors.file_header_errors);
  }
  if (Array.isArray(etlValidationErrors?.row_errors)) {
    allErrors = allErrors.concat(etlValidationErrors.row_errors);
  }
  if (Array.isArray(etlValidationErrors?.file_footer_errors)) {
    allErrors = allErrors.concat(etlValidationErrors.file_footer_errors);
  }
  return consolidateMessages(allErrors, { separator: '<br/>' });
}

export function createEtlTaskTracker$(
  gqlService: GqlService,
  eventId: string
): Observable<EtlTaskStatus> {
  if (!(gqlService instanceof GqlService)) {
    throw new Error('gqlService is required');
  }

  let keepRunning = true;
  let state = EtlTaskTrackerState.EtlInitial;
  let startedAtTimestamp = 0;
  let validationTrackerId = '';
  let nextValidationPollingTimestamp = 0;
  let dataLoadTrackerId = '';
  let nextDataLoadPollingTimestamp = 0;

  return new Observable<EtlTaskStatus>((subscriber) => {
    function safeNext(val: EtlTaskStatus): void {
      try {
        if (keepRunning) {
          subscriber.next(val);
        }
      } catch (ex) {
        console.warn('Supressing the error thrown by the subscriber.next(val)', ex);
      }
    }

    function safeError(err: unknown): void {
      try {
        if (keepRunning) {
          subscriber.error(err);
        }
      } catch (ex) {
        console.warn('Supressing the error thrown by the subscriber.error(err)', ex);
      }
    }

    function safeComplete(): void {
      try {
        if (keepRunning) {
          subscriber.complete();
        }
      } catch (ex) {
        console.warn('Supressing the error thrown by the subscriber.complete()', ex);
      }
    }

    function verifyValidState(nextState: EtlTaskTrackerState): boolean {
      return (
        Number.isInteger(nextState) &&
        nextState >= EtlTaskTrackerState.EtlInitial &&
        nextState <= EtlTaskTrackerState.EtlFinal
      );
    }

    function setState(nextState: EtlTaskTrackerState, withParams?: unknown): void {
      if (!verifyValidState(state)) {
        throw new Error(`Invalid current state: ${state}`);
      }
      if (!verifyValidState(nextState)) {
        throw new Error(
          `Invalid state transition from ${EtlTaskTrackerState[state]} to ${nextState}`
        );
      }

      // helpers
      function verifyIncomingTrackerIdParam(phase: EtlPhase): string {
        if (typeof withParams !== 'string' || !withParams) {
          throw new Error(`Invalid tracker id param (${phase})!`);
        }
        return withParams;
      }
      function verifyIncomingEtlValidationTaskStatusParam(): EtlValidationTaskStatus {
        if (
          typeof withParams !== 'object' ||
          (withParams as EtlTaskStatus)?.phase !== EtlPhase.Validation
        ) {
          throw new Error('Invalid EtlValidationTaskStatus param');
        }
        return withParams as EtlValidationTaskStatus;
      }
      function verifyIncomingEtlDataLoadTaskStatusParam(): EtlDataLoadTaskStatus {
        if (
          typeof withParams !== 'object' ||
          (withParams as EtlTaskStatus)?.phase !== EtlPhase.DataLoad
        ) {
          throw new Error('Invalid EtlDataLoadTaskStatus param');
        }
        return withParams as EtlDataLoadTaskStatus;
      }
      function verifyIncomingErrorParam(): Error {
        if (withParams === undefined || withParams === null) {
          throw new Error('Invalid Error param');
        }
        if (withParams instanceof Error) {
          return withParams;
        }
        const msg = typeof withParams === 'string' ? withParams.trim() : JSON.stringify(withParams);
        return new Error(msg || 'Unknown etl error');
      }

      const prev_state = state;
      let transitionPerformed = false;
      state = nextState;
      try {
        // on-enter-state actions
        if (nextState === EtlTaskTrackerState.EtlInitial) {
          startedAtTimestamp = 0;
        } else if (nextState === EtlTaskTrackerState.InitializeValidationMonitor) {
          validationTrackerId = verifyIncomingTrackerIdParam(EtlPhase.Validation);
        } else if (nextState === EtlTaskTrackerState.ValidationInProgress) {
          const valStatus = verifyIncomingEtlValidationTaskStatusParam();
          safeNext(valStatus);
        } else if (nextState === EtlTaskTrackerState.ValidationComplete) {
          const valStatus = verifyIncomingEtlValidationTaskStatusParam();
          safeNext({ ...valStatus, should_update_progress: true, percent_complete: 100 });
          if (valStatus.val_next_tracker_id) {
            setState(EtlTaskTrackerState.InitializeDataLoadMonitor, valStatus.val_next_tracker_id);
          } else {
            setState(EtlTaskTrackerState.EtlFinal);
          }
        } else if (nextState === EtlTaskTrackerState.ValidationFailed) {
          const valStatus = verifyIncomingEtlValidationTaskStatusParam();
          safeNext(valStatus);
          setState(EtlTaskTrackerState.EtlFinal);
        } else if (nextState === EtlTaskTrackerState.InitializeDataLoadMonitor) {
          dataLoadTrackerId = verifyIncomingTrackerIdParam(EtlPhase.DataLoad);
        } else if (nextState === EtlTaskTrackerState.DataLoadInProgress) {
          const dlStatus = verifyIncomingEtlDataLoadTaskStatusParam();
          safeNext(dlStatus);
        } else if (nextState === EtlTaskTrackerState.DataLoadComplete) {
          const dlStatus = verifyIncomingEtlDataLoadTaskStatusParam();
          safeNext({ ...dlStatus, should_update_progress: true, percent_complete: 100 });
          setState(EtlTaskTrackerState.EtlFinal);
        } else if (nextState === EtlTaskTrackerState.DataLoadFailed) {
          const dlStatus = verifyIncomingEtlDataLoadTaskStatusParam();
          safeNext(dlStatus);
          setState(EtlTaskTrackerState.EtlFinal);
        } else if (nextState === EtlTaskTrackerState.EtlError) {
          const err = verifyIncomingErrorParam();
          safeError(err);
          keepRunning = false;
        } else if (nextState === EtlTaskTrackerState.EtlFinal) {
          safeComplete();
          keepRunning = false;
        }
        //
        transitionPerformed = true;
      } finally {
        if (!transitionPerformed) {
          state = prev_state;
        }
      }
    }

    async function getEtlTaskStatus(id: string): Promise<EtlTaskStatus | undefined> {
      const trackerResponse = await firstValueFrom(gqlService.getEventTracker$(id));
      if (Array.isArray(trackerResponse.errors) && trackerResponse.errors.length) {
        const msg =
          trackerResponse.errors.length === 1
            ? trackerResponse.errors[0]
            : JSON.stringify(trackerResponse.errors);
        throw new Error(`Failed to get the etl tracker: ${msg}`);
      }
      if (trackerResponse.data) {
        let trackerPayload = trackerResponse.data.payload
          ? JSON.parse(trackerResponse.data.payload)
          : undefined;
        if (typeof trackerPayload !== 'object' || !trackerPayload) {
          trackerPayload = {};
        }

        const cp = trackerPayload as EtlTaskStatus;
        cp.phase = EtlPhase.Unknown;
        cp.raw_response = trackerResponse.data;
        cp.is_in_progress =
          trackerResponse.data.event_status !== EventStatus.EVENT_STATUS_COMPLETED &&
          trackerResponse.data.event_status !== EventStatus.EVENT_STATUS_FAILED;
        cp.should_update_progress = false;
        if (!cp.event_type) {
          cp.event_type = trackerResponse.data.event_type;
          cp.bucket_key = '';
          cp.file_signature = '';
        }
        if (!cp.success_notification_message) {
          cp.success_notification_message = trackerResponse.data.success_message || 'Etl Success';
        }
        if (!cp.error_notification_message) {
          cp.error_notification_message = trackerResponse.data.error_message || 'Etl Failure';
        }

        if (trackerResponse.data.event_type === EventType.VALIDATE_UPLOADED_FILE) {
          const valPayload = trackerPayload as EtlValidationTaskStatus;
          valPayload.phase = EtlPhase.Validation;
          valPayload.percent_complete = valPayload.val_percent_complete || 0;
          valPayload.completion_error_message = valPayload.val_error_message;
        } else {
          const dlPayload = trackerPayload as EtlDataLoadTaskStatus;
          dlPayload.phase = EtlPhase.DataLoad;
          if (!Number.isFinite(dlPayload.percent_complete)) {
            dlPayload.percent_complete = dlPayload.is_in_progress ? 10 : 100;
          }
          dlPayload.completion_error_message = '';
          if (trackerResponse.data.event_status === EventStatus.EVENT_STATUS_COMPLETED) {
            dlPayload.dl_status = EtlSubtaskStatus.Success;
          } else if (trackerResponse.data.event_status === EventStatus.EVENT_STATUS_FAILED) {
            dlPayload.dl_status = EtlSubtaskStatus.Error;
          } else {
            dlPayload.dl_status = EtlSubtaskStatus.Pending;
          }
        }

        if (trackerResponse.data.event_status === EventStatus.EVENT_STATUS_FAILED) {
          if (!cp.completion_error_message) {
            cp.completion_error_message = 'Etl task failed!';
          }
        }

        return trackerPayload;
      }
      return undefined;
    }

    async function performPendingStateTransitions(): Promise<void> {
      if (!verifyValidState(state)) {
        throw new Error(`Invalid current state: ${state}`);
      }
      const currentTimestamp = new Date().getTime();
      try {
        if (state === EtlTaskTrackerState.EtlInitial) {
          startedAtTimestamp = currentTimestamp;
          setState(EtlTaskTrackerState.InitializeValidationMonitor, eventId);
        } else {
          const elapsedSeconds = (currentTimestamp - startedAtTimestamp) / 1000;
          if (elapsedSeconds > MAX_RUN_LENGTH_SECONDS) {
            throw new Error('Etl timeout!');
          }
        }

        if (
          state === EtlTaskTrackerState.InitializeValidationMonitor ||
          (state === EtlTaskTrackerState.ValidationInProgress &&
            currentTimestamp >= nextValidationPollingTimestamp)
        ) {
          nextValidationPollingTimestamp =
            currentTimestamp + VALIDATION_POLLING_PERIOD_SECONDS * 1000;
          const valStatus = await getEtlTaskStatus(validationTrackerId);
          if (!valStatus || valStatus?.phase !== EtlPhase.Validation) {
            throw new Error(`Etl task not found: ${validationTrackerId}`);
          }
          if (valStatus.is_in_progress) {
            setState(EtlTaskTrackerState.ValidationInProgress, valStatus);
          } else if (valStatus.completion_error_message) {
            setState(EtlTaskTrackerState.ValidationFailed, valStatus);
          } else {
            setState(EtlTaskTrackerState.ValidationComplete, valStatus);
          }
        }

        if (
          state === EtlTaskTrackerState.InitializeDataLoadMonitor ||
          (state === EtlTaskTrackerState.DataLoadInProgress &&
            currentTimestamp >= nextDataLoadPollingTimestamp)
        ) {
          nextDataLoadPollingTimestamp = currentTimestamp + DATA_LOAD_POLLING_PERIOD_SECONDS * 1000;
          const dlStatus = await getEtlTaskStatus(dataLoadTrackerId);
          if (!dlStatus || dlStatus?.phase !== EtlPhase.DataLoad) {
            throw new Error(`Etl data-load subtask not found: ${dataLoadTrackerId}`);
          }
          if (dlStatus.is_in_progress) {
            setState(EtlTaskTrackerState.DataLoadInProgress, dlStatus);
          } else if (dlStatus.completion_error_message) {
            setState(EtlTaskTrackerState.DataLoadFailed, dlStatus);
          } else {
            setState(EtlTaskTrackerState.DataLoadComplete, dlStatus);
          }
        }
      } catch (err) {
        setState(EtlTaskTrackerState.EtlError, err);
      }

      if (
        keepRunning &&
        state !== EtlTaskTrackerState.EtlFinal &&
        state !== EtlTaskTrackerState.EtlError
      ) {
        setTimeout(() => {
          performPendingStateTransitions().catch((err) => {
            console.error('Failed to schedule the etl monitoring continuation task', err);
            setState(EtlTaskTrackerState.EtlError, err);
          });
        }, WAKE_UP_PERIOD_MSECS);
      }
    }

    setTimeout(() => {
      performPendingStateTransitions().catch((err) => {
        console.error('Failed to start the etl monitoring task', err);
        setState(EtlTaskTrackerState.EtlError, err);
      });
    }, 0);

    return () => (keepRunning = false);
  });
}

export function createEtlTaskStatusConsoleSubscriber(
  apiService: ApiService,
  overlayService: OverlayService
) {
  let discardTemplateFileBucketKey = '';
  return {
    next: (etlTaskStatus: EtlTaskStatus) => {
      if (etlTaskStatus.phase === EtlPhase.Validation) {
        if (etlTaskStatus.is_in_progress || etlTaskStatus.should_update_progress) {
          console.log(
            `ETL-next ${new Date().toISOString()}`,
            ` * validation | progress: ${etlTaskStatus.percent_complete}`
          );
          // UI ACTION
          // update the progress bar; percent_complete is in range [0 .. 100]
        }
        if (!etlTaskStatus.is_in_progress) {
          const ets = etlTaskStatus as EtlValidationTaskStatus;
          // validation finished; now, check how it went
          discardTemplateFileBucketKey = ets.bucket_key;
          const validationErrorMessages = consolidateEtlValidationErrors(ets.val_errors);
          if (ets.completion_error_message || ets.val_status === EtlSubtaskStatus.Error) {
            const errorMessage = `Validation Failure: ${ets.completion_error_message}${validationErrorMessages ? '<br/>' : ''}${validationErrorMessages}`;
            console.log(
              `ETL-next ${new Date().toISOString()}`,
              ` * validation | validation failed due to run-time error: ${errorMessage}`
            );
            // UI ACTION
            overlayService.error(errorMessage, undefined, true);
          } else if (validationErrorMessages || ets.val_status !== EtlSubtaskStatus.Success) {
            const msg =
              validationErrorMessages || 'received an inconsistent status update from the backend!';
            const errorMessage = `Validation Failure: ${msg}`;
            console.log(
              `ETL-next ${new Date().toISOString()}`,
              ` * validation | validation failed gracefully with business error: ${errorMessage}`
            );
            // UI ACTION
            overlayService.error(errorMessage, undefined, true);
          } else {
            discardTemplateFileBucketKey = '';
            console.log(
              `ETL-next ${new Date().toISOString()}`,
              ` * validation | validation completed successfully. Hide validation related stuff`
            );
            // UI ACTION
            overlayService.success('Template file has been validated');
          }
          // CLEAN UP
          if (ets.val_status !== EtlSubtaskStatus.Success) {
            apiService.removeFile(ets.bucket_key).catch((err) => {
              console.warn(`Failed to remove the template file: ${ets.bucket_key}`, err);
            });
          }
        }
      } else {
        if (etlTaskStatus.is_in_progress || etlTaskStatus.should_update_progress) {
          // currently backend data loaders don't do progress updates. so, we are faking this for now.
          // the observable will immediately report 10% progress and then it will suddenly jump to 100% when/if the data loader finishes its job successfully
          console.log(
            `ETL-next ${new Date().toISOString()}`,
            ` * data-load | progress: ${etlTaskStatus.percent_complete}`
          );
          // UI ACTION
          // update the progress bar; percent_complete is in range [0 .. 100]
        }
        if (!etlTaskStatus.is_in_progress) {
          const ets = etlTaskStatus as EtlDataLoadTaskStatus;
          // data-load finished; now, check how it went
          if (ets.completion_error_message) {
            console.log(
              `ETL-next ${new Date().toISOString()}`,
              ` * data-load | data load failed: ${ets.error_notification_message}`
            );
            // UI ACTION
            overlayService.error(ets.error_notification_message, undefined, true);
          } else {
            console.log(
              `ETL-next ${new Date().toISOString()}`,
              ` * data-load | data load successful: ${ets.success_notification_message}`
            );
            // UI ACTION
            overlayService.success(ets.success_notification_message);
          }
        }
      }
    },
    error: (err: unknown) => {
      const errorMessage = `Data Import Error: ${err instanceof Error ? err.message : String(err)}`;
      console.log(
        `ETL-error ${new Date().toISOString()}`,
        'Notify the user of the run-time problem',
        errorMessage
      );
      // UI ACTION
      overlayService.error(errorMessage, undefined, true);
      // CLEAN UP
      if (discardTemplateFileBucketKey) {
        apiService.removeFile(discardTemplateFileBucketKey).catch((e) => {
          console.warn(`Failed to remove the template file: ${discardTemplateFileBucketKey}`, e);
        });
      }
    },
    complete: () => {
      console.log(`ETL-complete ${new Date().toISOString()}`);
    },
  };
}
