import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ApiService, FileMetadata } from '@services/api.service';
import { Utils } from '@services/utils';
import { File } from '@components/file-manager/state/file.model';
import { FileStore } from './state/file.store';
import { FileQuery } from './state/file.query';
import { FileService } from './state/file.service';
import { NgClass, NgIf } from '@angular/common';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';

@Component({
  selector: 'aux-file-manager',
  templateUrl: './file-manager.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  providers: [FileStore, FileQuery, FileService],
  standalone: true,
  imports: [NgIf, NgClass, TooltipDirective],
})
export class FileManagerComponent implements OnInit {
  @ViewChild('uploadInput') uploadInput!: ElementRef;

  @Input() path = '';

  @Input() class = '';

  @HostBinding('attr.class') get classAttribute() {
    return '';
  }

  @Input() accept = Utils.ALLOWED_FILE_EXTENSIONS.join(',');

  @Input() pathFn: (() => string) | undefined;

  @Input() subText = '';

  @Input() fetchFilesOnInit = false;

  @Input() disableUploadLink = false;

  @Input() linkTooltip = '';

  @Input() eager = true;

  @Input() metadata: FileMetadata = {};

  @Input() extensions: string[] = Utils.ALLOWED_FILE_EXTENSIONS;

  @Input() metadataFn: (() => FileMetadata) | undefined;

  @Input() showSuccessOnUpload = false;

  @Input() insertDocument = false;

  @Input() document_entity_id: string | undefined | null;

  @Input() document_entity_type_id: string | undefined | null;

  @Input() document_type_id: string | undefined | null;

  @Input() site_id: string | undefined | null;

  @Input() showSpecificError = false;

  @Input() entityIdS3Uploading = false;

  @Input() disabled = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onUploadSuccess: EventEmitter<unknown> = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFilesAdded: EventEmitter<unknown> = new EventEmitter();

  @Output() fileManagerReady = new EventEmitter<FileManagerComponent>();

  className = 'border-2 border-dashed rounded-md flex items-center justify-between p-0.5 relative';

  hasInvalidFiles = false;

  constructor(
    public apiService: ApiService,
    public fileQuery: FileQuery,
    public fileStore: FileStore,
    public fileService: FileService
  ) {
    this.fileStore.setLoading(false);
  }

  async fetchFiles() {
    await this.fileService.fetchFiles(
      this.path,
      this.document_entity_id ? this.document_entity_id : undefined,
      this.document_entity_type_id ? this.document_entity_type_id : undefined,
      this.document_type_id ? this.document_type_id : undefined,
      this.site_id ? this.site_id : undefined
    );
  }

  getPath() {
    if (this.pathFn) {
      this.path = this.pathFn();
    }

    return this.path;
  }

  getMetadata() {
    if (this.metadataFn) {
      this.metadata = this.metadataFn();
    }

    return this.metadata;
  }

  ngOnInit(): void {
    this.className = `${this.className} ${this.class}`;
    this.getPath();

    if (this.fetchFilesOnInit) {
      this.fetchFiles();
    }

    this.fileManagerReady.emit(this);
  }

  async removeFile(file: File) {
    const success = await this.fileService.remove(file);

    if (success) {
      this.uploadInput.nativeElement.value = '';
    }

    return success;
  }

  removeFileSoft(file: File) {
    this.fileStore.remove(file.id);
    this.uploadInput.nativeElement.value = '';
  }

  getValidFiles = (fileList: FileList | null): FileList | null => {
    let validFiles: FileList | null;
    const dataTransfer = new DataTransfer();

    if (this.extensions.length && fileList?.length) {
      for (let i = 0; i < fileList.length; i++) {
        if (this.extensions.includes(fileList[i].type)) {
          dataTransfer.items.add(fileList[i]);
        }
      }

      validFiles = dataTransfer.files;
    } else {
      validFiles = fileList;
    }

    this.hasInvalidFiles = fileList?.length !== validFiles?.length;

    return validFiles;
  };

  addFiles(event: Event) {
    if (this.disabled || this.disableUploadLink) {
      return;
    }

    const element = event.currentTarget as HTMLInputElement;
    const validFiles = this.getValidFiles(element.files);
    if (validFiles?.length) {
      this.fileService.add(validFiles, this.getPath(), this.entityIdS3Uploading);

      this.onFilesAdded.emit();

      if (this.eager) {
        this.uploadFiles();
      }
    }
  }

  async uploadFiles() {
    const fileSuccess = await this.fileService.uploadFiles(
      this.getMetadata(),
      this.showSuccessOnUpload,
      this.insertDocument
    );

    if (fileSuccess) {
      this.onUploadSuccess.emit();
    }
  }

  resetUploadInput() {
    this.uploadInput.nativeElement.value = '';
  }
}
