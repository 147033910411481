import { Injectable } from '@angular/core';
import { Utils, ValuesType } from '@services/utils';
import { AmountType, ExpenseType, listBudgetGridQuery } from '@services/gql.service';
import { OrganizationModel } from '@models/organization/organization.store';
import { groupBy, isArray } from 'lodash-es';
import dayjs from 'dayjs';
import {
  decimalAdd,
  decimalDifference,
  decimalDivide,
  decimalRoundingToNumber,
} from '@shared/utils';

import { MainQuery } from '@shared/store/main/main.query';
import { BudgetCurrencyType } from '../budget-type';
import { ExtendedBudgetData } from './budget.model';

export type BudgetDataArrayType = listBudgetGridQuery['budget_data'];

export type BudgetDataType = ValuesType<NonNullable<BudgetDataArrayType>>;

type Expenses = ValuesType<NonNullable<BudgetDataType['expenses']>>;

type MonthDate = { month: string; year: string };

@Injectable({
  providedIn: 'root',
})
export class BudgetGridService {
  constructor(private mainQuery: MainQuery) {}

  private readonly expenseTypes: ExpenseType[] = [
    ExpenseType.EXPENSE_FORECAST,
    ExpenseType.EXPENSE_WP,
  ];

  getBudgetInfo(budgetInfo: listBudgetGridQuery['budget_info'], vendors: OrganizationModel[]) {
    return (budgetInfo || []).map((bd) => {
      const vendor = vendors.find((v) => v.id === bd.vendor_id);
      const vendor_name = vendor?.name || bd.vendor_id;
      return {
        ...bd,
        vendor_name,
      };
    });
  }

  private getExpensesForCostCategory = (
    groupedExpenses: Record<ExpenseType, Expenses[]>,
    amountType: AmountType,
    currency: BudgetCurrencyType,
    customExpensesKey?: string
  ): Record<string, number> => {
    return (groupedExpenses[ExpenseType.EXPENSE_QUOTE] || [])
      .filter((expense: NonNullable<Expenses>) => expense.amount_type === amountType)
      .reduce((accum, expense) => {
        return {
          ...accum,
          [customExpensesKey || ExpenseType.EXPENSE_QUOTE]:
            Number(
              currency === BudgetCurrencyType.USD ? expense.amount : expense.contract_amount
            ) || 0,
        };
      }, {});
  };

  private groupExpensesByCostCategory(
    costCategory: string,
    groupedExpenses: Record<ExpenseType, Expenses[]>,
    currency: BudgetCurrencyType,
    customExpensesKey?: string
  ): Record<string, number> {
    const mapCostCategory = new Map<string, () => Record<string, number>>([
      [
        'Services',
        () =>
          this.getExpensesForCostCategory(
            groupedExpenses,
            AmountType.AMOUNT_SERVICE,
            currency,
            customExpensesKey
          ),
      ],
      [
        'Discount',
        () =>
          this.getExpensesForCostCategory(
            groupedExpenses,
            AmountType.AMOUNT_DISCOUNT,
            currency,
            customExpensesKey
          ),
      ],
      [
        'Investigator',
        () =>
          this.getExpensesForCostCategory(
            groupedExpenses,
            AmountType.AMOUNT_INVESTIGATOR,
            currency,
            customExpensesKey
          ),
      ],
      [
        'Pass-through',
        () =>
          this.getExpensesForCostCategory(
            groupedExpenses,
            AmountType.AMOUNT_PASSTHROUGH,
            currency,
            customExpensesKey
          ),
      ],
    ]);

    const fn = mapCostCategory.get(costCategory);

    return fn ? fn() : {};
  }

  private getPeriodExpensesData(
    groupedExpenses: Record<ExpenseType, Expenses[]>,
    currency: BudgetCurrencyType
  ): Record<string, number> {
    return this.expenseTypes.reduce((accum, expensesKey) => {
      return {
        ...accum,
        ...(groupedExpenses[expensesKey] || []).reduce((expenses, expense) => {
          return {
            ...expenses,
            [`${expensesKey}::${expense.period}`]:
              Number(
                currency === BudgetCurrencyType.USD ? expense.amount : expense.contract_amount
              ) || 0,
            // always want to show the graph above the budget grid in USD so adding a EXPENSE_FORECAST_USD key with the USD amount
            [`${expensesKey}_USD::${expense.period}`]: Number(expense.amount) || 0,
          };
        }, {}),
      };
    }, {});
  }

  private getDataForPlan(
    groupedExpenses: Record<ExpenseType, Expenses[]>,
    currency: BudgetCurrencyType,
    expensesByCost: Record<string, number>
  ): Record<string, number> {
    const result: Record<string, number> = {};
    [
      ExpenseType.EXPENSE_ACCRUAL,
      ExpenseType.EXPENSE_FORECAST_AT_CLOSE,
      ExpenseType.EXPENSE_WP,
    ].forEach((expense_type) => {
      (groupedExpenses[expense_type] || []).forEach(({ period, amount, contract_amount }) => {
        const expenseAmount = currency === BudgetCurrencyType.USD ? amount : contract_amount;

        const actuals = expensesByCost[`${ExpenseType.EXPENSE_WP}::${period}`];
        const plan = result[`${period}::PLAN`] || Number(expenseAmount) || 0;
        const varCost = decimalDifference(actuals, plan || 0);

        result[`${period}::PLAN`] = plan || 0;
        result[`${period}::VAR_COST`] = varCost;
        result[`${period}::VAR_PERC`] = plan
          ? decimalRoundingToNumber(decimalDivide(varCost, plan), 3)
          : 0;
      });
    });
    return result;
  }

  private getForecastValue(
    groupedExpenses: Record<ExpenseType, Expenses[]>,
    currency: BudgetCurrencyType
  ) {
    return (groupedExpenses[ExpenseType.EXPENSE_FORECAST] || []).reduce(
      (_, expense) =>
        (currency === BudgetCurrencyType.USD ? expense.amount : expense.contract_amount) || 0,
      0
    );
  }

  private getVendorName(vendors: OrganizationModel[], budgetData: BudgetDataType) {
    const vendor = vendors.find((v) => v.id === budgetData.vendor_id);
    return vendor?.name || budgetData.vendor_id;
  }

  getVarPercent(current_lre: number, var_amount: number, baseline: number) {
    let var_percent = 0;

    if (baseline) {
      var_percent = current_lre ? (var_amount / Math.abs(baseline)) * 100 : -100;
    }

    return decimalRoundingToNumber(var_percent, 3);
  }

  private getBaseLineV2(row: NonNullable<BudgetDataArrayType>[0], currency: BudgetCurrencyType) {
    const expense = row.expenses?.find(
      (exp) => exp.expense_type === ExpenseType.EXPENSE_BASELINE_QUOTE
    );
    return (currency === BudgetCurrencyType.USD ? expense?.amount : expense?.contract_amount) || 0;
  }

  getBudgetGridWithBaseLineForVendorsV2(
    budgetData: BudgetDataArrayType,
    vendors: OrganizationModel[],
    currency = BudgetCurrencyType.USD
  ) {
    const gridData: ExtendedBudgetData[] = this.getBaselineBudgetData(
      budgetData,
      vendors,
      currency,
      true
    );

    return gridData.map((row) => {
      const { current_lre } = row;

      const baseline = decimalRoundingToNumber(this.getBaseLineV2(row, currency) || 0, 3);

      const var_amount = decimalRoundingToNumber((current_lre || 0) - baseline, 3);

      return <ExtendedBudgetData>(<unknown>{
        ...row,
        baseline: baseline || 0,
        var_amount,
        var_percent: this.getVarPercent(current_lre, var_amount, baseline),
      });
    });
  }

  getBaselineBudgetData(
    budgetData: BudgetDataArrayType,
    vendors: OrganizationModel[],
    currency: BudgetCurrencyType,
    shouldRoundTheBaseline = false
  ) {
    return (budgetData || []).map((rowData) => {
      const { unit_cost, unit_num, contract_unit_cost_currency, contract_unit_cost } = rowData;
      const groupedExpenses = groupBy(rowData.expenses, 'expense_type') as Record<
        ExpenseType,
        Expenses[]
      >;
      const costCategoryExpenses = this.groupExpensesByCostCategory(
        rowData.cost_category as string,
        groupedExpenses,
        currency,
        ''
      );

      const periodExpensesData = this.getPeriodExpensesData(groupedExpenses, currency);

      const newExpenses = {
        ...costCategoryExpenses,
        ...periodExpensesData,
        ...this.getDataForPlan(groupedExpenses, currency, {
          ...costCategoryExpenses,
          ...periodExpensesData,
        }),
      };

      let current_lre = newExpenses[`${ExpenseType.EXPENSE_QUOTE}`] || 0;

      if (shouldRoundTheBaseline) {
        current_lre = decimalRoundingToNumber(current_lre, 3);
      }

      const wp_cost = newExpenses[`${ExpenseType.EXPENSE_WP}::TO_DATE`] || 0;
      const wp_percentage = decimalDivide(wp_cost, current_lre) * 100 || 0;
      const remaining_cost = decimalDifference(current_lre, wp_cost);

      const remaining_percentage = current_lre ? decimalDifference(100, wp_percentage) : 0;
      const var_amount = 0;

      let wp_unit_num = 0;

      if (unit_cost && currency === BudgetCurrencyType.USD) {
        wp_unit_num = decimalDivide(wp_cost, unit_cost);
      }
      if (contract_unit_cost && currency === BudgetCurrencyType.VENDOR) {
        wp_unit_num = decimalDivide(wp_cost, contract_unit_cost);
      }

      let account_value = '';
      let po_value = '';
      let dept_value = '';

      const remaining_unit_num = decimalDifference(unit_num || 0, wp_unit_num);
      if (isArray(rowData?.attributes) && rowData.attributes) {
        account_value =
          rowData.attributes.find((x) => x.attribute === 'account_no')?.attribute_value || '';
        po_value = rowData.attributes.find((x) => x.attribute === 'po_no')?.attribute_value || '';
        dept_value =
          rowData.attributes.find((x) => x.attribute === 'department')?.attribute_value || '';
      }

      return <ExtendedBudgetData>(<unknown>{
        ...rowData,
        ...newExpenses,
        vendor_name: this.getVendorName(vendors, rowData),
        contract_unit_cost_currency,
        trial_to_date: this.getTrialToDate(groupedExpenses, currency),
        wp_unit_num,
        remaining_unit_num,
        forecast: this.getForecastValue(groupedExpenses, currency),
        current_lre,
        baseline: current_lre,
        var_amount,
        var_percent: this.getVarPercent(current_lre, var_amount, current_lre),
        remaining_cost,
        remaining_percentage,
        wp_percentage,
        wp_cost,
        accrual: 0,
        total_monthly_accrual: 0,
        adjustment: 0,
        account_value,
        po_value,
        dept_value,
      });
    });
  }

  private getTrialToDate(
    groupedExpenses: Record<ExpenseType, Expenses[]>,
    currency: BudgetCurrencyType
  ) {
    let trial_to_date = 0;
    const { auxilius_start_date } = this.mainQuery.getSelectedTrial() || {};
    if (auxilius_start_date) {
      (groupedExpenses[ExpenseType.EXPENSE_WP] || []).forEach((x) => {
        if (x.period) {
          if (
            dayjs(auxilius_start_date)
              .date(1)
              .isAfter(dayjs(`01/${x.period.replace('-', '/')}`))
          ) {
            trial_to_date = decimalAdd(
              trial_to_date,
              (currency === BudgetCurrencyType.USD ? x.amount : x.contract_amount) || 0
            );
          }
        }
      });
    }

    return trial_to_date;
  }

  getMonthsBeforeAuxiliusStartForQuarterCalc(auxilius_start_date: string): string[] {
    const keysForMonthsBeforeAuxStartForQuarter: string[] = [];
    const monthsBeforeAuxStart =
      this.getBeforeMonthsOfAuxiliusStartDateForQuarter(auxilius_start_date);
    monthsBeforeAuxStart.forEach((beforMonth) => {
      keysForMonthsBeforeAuxStartForQuarter.push(
        `EXPENSE_WP::${beforMonth.month}-${beforMonth.year}`
      );
    });
    return keysForMonthsBeforeAuxStartForQuarter;
  }

  getBeforeMonthsOfAuxiliusStartDateForQuarter(auxStart: string): MonthDate[] {
    const [year, month] = auxStart.split('-');
    const monthNumber = +month;
    const quarter = dayjs(auxStart).quarter();
    const allMonths = Utils.SHORT_MONTH_NAMES;
    const previousMonths: MonthDate[] = [];
    for (let index = (quarter - 1) * 3 + 1; index < monthNumber; index++) {
      previousMonths.push({ month: allMonths[index - 1].toUpperCase(), year });
    }
    return previousMonths;
  }
}
