<ng-container *ngIf="inAppBudget === false; else BudgetTemplate">
  <section
    *ngFor="let file of files$ | async; first as first"
    class="border-2 rounded-md h-32 p-2 relative mt-4"
    [ngClass]="{ 'mt-4': disableFirstFileMargin ? !first : true }"
  >
    <div class="flex items-center justify-between h-6">
      <aux-icon name="FileDescription" class="-ml-1" [size]="20" />
      <div class="flex items-center justify-between h-6">
        <button
          class="mr-2 w-6 h-6 text-aux-blue flex justify-center items-center"
          type="button"
          auxTooltip="Download"
          (click)="onDownload(file)"
        >
          <aux-icon name="Download" [size]="20" />
        </button>
        <button type="button" class="aux-link focus:outline-none" (click)="onRemove(file)">
          Remove
        </button>
      </div>
    </div>
    <div class="h-12 mt-2">
      <div class="font-semibold line-clamp-2">{{ file.fileName }}</div>

      <div
        *ngIf="file.created_by && file.create_date && showUserAndDate"
        class="line-clamp-2 italic"
      >
        Uploaded By: {{ userFormatter(file.created_by) }}
        {{ file.create_date | date }}
      </div>
      <ng-content></ng-content>
    </div>
  </section>
</ng-container>

<ng-container *ngIf="loading$ | async">
  <div class="border border-blue-200 shadow rounded-md p-2 max-w-sm w-full mt-4">
    <div class="animate-pulse flex space-x-4">
      <div class="flex-1 space-y-4 py-1">
        <div class="flex justify-between">
          <div class="h-8 w-7 bg-blue-200 rounded"></div>
          <div class="grow"></div>
          <div class="h-4 bg-blue-200 rounded w-1/6"></div>
        </div>
        <div class="space-y-2">
          <div class="h-3 bg-blue-200 rounded w-3/6"></div>
          <div class="h-0.5"></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #BudgetTemplate>
  <section
    *ngFor="let file of files$ | async; first as first"
    class="border-2 rounded-md min-h-12 px-2 relative mt-4 border-gray-200 flex items-center"
    [ngClass]="{ 'mt-4': disableFirstFileMargin ? !first : true }"
  >
    <div class="flex items-center justify-between w-full">
      <div class="flex justify-start items-center">
        <aux-icon name="FileDescription" class="-ml-1 mr-1 min-w-[24px]" [size]="24" />
        <div class="max-w-[280px] h-fit break-all">
          {{ file.fileName }}
        </div>
      </div>
      <div class="flex items-center space-x-2">
        <button
          class="mr-2 w-6 h-6 text-aux-blue flex justify-center items-center"
          type="button"
          auxTooltip="Download"
          (click)="onDownload(file)"
        >
          <aux-icon name="Download" [size]="20" />
        </button>
        <button type="button" class="w-6 h-6 justify-center items-center" (click)="onRemove(file)">
          <aux-icon class="text-aux-error cursor-pointer" name="Trash" [size]="20" />
        </button>
      </div>
    </div>
  </section>
</ng-template>
