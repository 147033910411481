import { Injectable } from '@angular/core';
import { QueryEntity, QueryConfig, Order } from '@datorama/akita';
import { BudgetType, EntityType, OrganizationType } from '@services/gql.service';
import { OrganizationStore, VendorsState } from './organization.store';
import { toSignal } from '@angular/core/rxjs-interop';

@QueryConfig({
  sortBy: 'name',
  sortByOrder: Order.ASC,
})
@Injectable({ providedIn: 'root' })
export class OrganizationQuery extends QueryEntity<VendorsState> {
  allVendors$ = this.selectAll({
    filterBy: (entity) => entity.organization_type === OrganizationType.ORGANIZATION_VENDOR,
  });

  allVendors = toSignal(this.allVendors$, { requireSync: true });

  allVendorsObj$ = this.selectAll({
    asObject: true,
    filterBy: (entity) => entity.organization_type === OrganizationType.ORGANIZATION_VENDOR,
  });

  constructor(protected store: OrganizationStore) {
    super(store);
  }

  getAllVendors() {
    return this.getAll({
      filterBy: (entity) => entity.organization_type === OrganizationType.ORGANIZATION_VENDOR,
    });
  }

  getVendor(vendor_id: string) {
    return this.getAll({
      filterBy: (entity) =>
        entity.organization_type === OrganizationType.ORGANIZATION_VENDOR &&
        entity.id === vendor_id,
    });
  }

  getBudgetVersion(
    org_id: string,
    budget_type: BudgetType,
    entity_id: string,
    entity_type: EntityType
  ) {
    const bv = this.getEntity(org_id)?.budget_version;

    return bv?.[`${budget_type}-${entity_id}-${entity_type}`];
  }

  getPrimaryBudgetVersion(org_id: string) {
    const bv = this.getEntity(org_id)?.budget_version;

    return bv?.[`${BudgetType.BUDGET_PRIMARY}-${org_id}-${EntityType.ORGANIZATION}`];
  }
}
