import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  ControlValueAccessor,
  FormControl,
  NgControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CustomValidators } from '@components/form-inputs/custom-validators';

interface ValidatorsObj {
  [k: string]: (param: string) => ValidatorFn;
}

const validatorsObj: ValidatorsObj = {
  required: () => Validators.required,
  email: () => CustomValidators.emailValidator(),
  minLength: (param) => Validators.minLength(+param),
  greaterThenZero: () => CustomValidators.greaterThanZeroValidator(),
};

let uid = 1;

@Directive()
export class BaseFormControlComponent<T> implements ControlValueAccessor, Validator {
  // eslint-disable-next-line no-plusplus
  uid = `aux-input-${uid++}`;

  @Input() label = '';

  @Input() placeholder = '';

  @Input() customErrorMessages: {
    [k: string]: string | ((fc: NgControl, error: unknown) => string);
  } = {};

  // todo change this in the future like https://github.com/wearebraid/vue-formulate/blob/master/src/libs/utils.js
  // for now this should work for 'required|email'
  @Input() set validators(inputValidators: string | ValidatorFn[] | ValidatorFn[]) {
    if (Array.isArray(inputValidators)) {
      this.fc.setValidators(inputValidators);
      this.fc.updateValueAndValidity();
      return;
    }
    const validators =
      !inputValidators || typeof inputValidators === 'string'
        ? (inputValidators || '')
            .split('|')
            .map((x) => x.split(':'))
            .map((value) => validatorsObj[value[0]](value[1]))
        : inputValidators;

    this.fc.setValidators(validators);
    this.fc.updateValueAndValidity();
  }

  @Input() updateOn: AbstractControlOptions['updateOn'] = 'change';

  fcInitialValue: T | null = null;

  fc = new FormControl<T | null>(this.fcInitialValue, { updateOn: this.updateOn });

  disabled = false;

  onChange: (p?: unknown) => void = () => {};

  onTouched = () => {};

  onValidatorChange = () => {};

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.fc.disable();
    } else {
      this.fc.enable();
    }
  }

  onBlur() {
    this.onValidatorChange();
  }

  writeValue(obj: T | null): void {
    if (obj === null) {
      this.fc.reset();
    }
    this.fc.setValue(obj);
  }

  registerOnValidatorChange(fn: () => void) {
    this.onValidatorChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.fc.dirty) {
      control.markAsDirty();
    } else if (control.dirty) {
      this.fc.markAsDirty();
    }

    if (this.fc.touched) {
      control.markAsTouched();
    } else if (control.touched) {
      this.fc.markAsTouched();
      this.onTouched();
    }

    this.fc.updateValueAndValidity();

    return this.fc.errors;
  }
}
