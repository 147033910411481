export class FormControlConstants {
  static readonly PLACEHOLDER = {
    DATE: 'mm/dd/yyyy',
    SELECT: 'Select',
  };

  static readonly MIN_VALUE = {
    DATE: '2000-01-01',
  };

  static readonly MAX_VALUE = {
    DATE: '9999-12-12',
  };

  static readonly VALIDATION_MESSAGE = {
    DATE: 'Dates prior to 01/01/2000 are not accepted.',
    MONTH_MIN: 'Months prior to current open month are not accepted.',
    MONTH_MAX: 'Months after trial end date are not accepted.',
    TRIAL_TIMELINE_PERIOD: 'Dates must be within trial timeline.',
    EMAIL: 'Invalid email.',
    GREATER_THEN_ZERO: 'Please enter a number greater than 0.',
    MUST_HAVE_LOWERCASE_CHAR: 'Must have a lowercase character.',
    MUST_HAVE_UPPERCASE_CHAR: 'Must have an uppercase character.',
    MUST_HAVE_NUMBER_CHAR: 'Must have a number character.',
    MIN_LENGTH: 'Minimum {{minlength.requiredLength}} character required.',
    REQUIRED: '{{label}} is required.',
    EMPTY_VENDORS: 'Please add vendor names to the field above.',
    DUPLICATED_SITE: 'Site # must be unique.',
    DUPLICATED_CHANGE_ORDER: 'Change Order # must be unique per vendor.',
    DUPLICATED_VENDORS: 'Duplicate vendor name not allowed.',
    DUPLICATED_MILESTONE_NAMES: `Duplicate milestone name not allowed.`,
    MILESTONES_TRACK_EACH_OTHER: 'Milestones cannot track each other.',
    SITE_CLOSEOUT: 'Site closeout date must be after activation date.',
    DUPLICATE_VERSION_NAME:
      'Duplicate Protocol Version. Please enter unique Protocol Version Name.',
    DUPLICATE_SITE_BUDGET: 'Site Budget Amendment name must be unique.',
    INCORRECT_SITE_BUDGET_EFFECTIVE_DATE:
      'The selected effective date is already in use by another site budget version.',
  };
}
