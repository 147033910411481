import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from '../input/input.component';
import { NgIf, NgComponentOutlet, NgClass } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { IconComponent } from '@components/icon/icon.component';

interface MaskProperties {
  prefix: string;
  suffix: string;
  mask: string;
  thousandSeparator: string;
  allowNegativeNumbers: boolean;
  dropSpecialCharacters: boolean;
  separatorLimit?: string;
}

type MaskType = 'money' | 'percentage' | 'limitedPercentage';

@Component({
  selector: 'aux-input-mask',
  templateUrl: './input-mask.component.html',
  styles: [
    `
      :host {
        display: block;
      }

      ::-webkit-input-placeholder {
        font-style: italic;
      }

      ::-moz-placeholder {
        font-style: italic;
      }

      /* remove arrows when number input */
      ::ng-deep input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button,
      input[type='number'] {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        margin: 0;
      }

      :-ms-input-placeholder {
        font-style: italic;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => InputMaskComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => InputMaskComponent),
      multi: true,
    },
    provideNgxMask(),
  ],
  standalone: true,
  imports: [
    NgIf,
    NgComponentOutlet,
    DirectivesModule,
    NgClass,
    ReactiveFormsModule,
    IconComponent,
    NgxMaskDirective,
    InputComponent,
  ],
})
export class InputMaskComponent extends InputComponent implements OnInit {
  @Input() maskType!: MaskType;

  @Input() mask? = '';

  private _allowNegativeNumbers = false;

  @Input() set allowNegativeNumbers(value: boolean) {
    this._allowNegativeNumbers = value;
    this.updateMaskOptions();
  }

  get allowNegativeNumbers() {
    return this._allowNegativeNumbers;
  }

  private _moneyPrefix = '$';

  @Input() set moneyPrefix(prefix: string) {
    this._moneyPrefix = prefix;
    this.updateMaskOptions();
  }
  get moneyPrefix() {
    return this._moneyPrefix;
  }

  mapMaskByType = new Map<MaskType, Partial<MaskProperties>>([
    [
      'money',
      {
        prefix: this.moneyPrefix,
        mask: 'separator',
        thousandSeparator: ',',
        allowNegativeNumbers: this.allowNegativeNumbers,
      },
    ],
    [
      'percentage',
      {
        suffix: '%',
        mask: 'separator.2',
        thousandSeparator: '',
        separatorLimit: '99999',
        allowNegativeNumbers: this.allowNegativeNumbers,
        dropSpecialCharacters: false,
      },
    ],
    [
      'limitedPercentage',
      {
        suffix: '%',
        mask: 'percent',
        allowNegativeNumbers: this.allowNegativeNumbers,
        dropSpecialCharacters: false,
      },
    ],
  ]);

  maskProperties: Partial<MaskProperties> = {};

  ngOnInit() {
    this.maskProperties = this.mapMaskByType.get(this.maskType) || { mask: this.mask };
  }

  onChangeValue(value: string | null) {
    let formattedValue: string | number | null = value;

    if (this.maskType === 'money') {
      formattedValue = this.formatMoneyToNumber(value);
    }

    if (this.maskType === 'percentage') {
      formattedValue = this.formatPercentToNumber(value);
    }

    this.onChange(formattedValue);
  }

  private formatMoneyToNumber(value?: string | null): number | null {
    if (!value) {
      return null;
    }

    const regex = new RegExp(`[${this.maskProperties.prefix || '$'},]`, 'g');

    return parseFloat(`${value}`.replace(regex, ''));
  }

  private formatPercentToNumber(value?: string | null): number {
    if (!value) {
      return 0;
    }

    return parseFloat(`${value}`);
  }

  private updateMaskOptions() {
    this.maskProperties = this.mapMaskByType.get(this.maskType) || { mask: this.mask };
    this.maskProperties.allowNegativeNumbers = this._allowNegativeNumbers;

    if (this.maskType === 'money') {
      this.maskProperties.prefix = this.moneyPrefix;
    }
  }
}
