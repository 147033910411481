import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  input,
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import dayjs from 'dayjs';
import { OverlayService } from '@services/overlay.service';
import { FormControlConstants } from '@constants/form-control.constants';
import { BaseFormControlComponent } from '../base-form-control';
import { NgClass, NgComponentOutlet, NgIf } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { AuxIconName } from '@components/icon/icon';
import { IconComponent } from '@components/icon/icon.component';

export interface DateControlPeriodParams {
  startDate: string;
  endDate: string;
}

@Component({
  selector: 'aux-input',
  templateUrl: './input.component.html',
  styles: [
    `
      :host {
        display: block;
      }

      ::-webkit-input-placeholder {
        font-style: italic;
      }

      :-moz-placeholder {
        font-style: italic;
      }

      ::-moz-placeholder {
        font-style: italic;
      }

      /* remove arrows when number input */
      ::ng-deep input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button,
      input[type='number'] {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        margin: 0;
      }

      :-ms-input-placeholder {
        font-style: italic;
      }
    `,
  ],
  styleUrls: ['input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgIf, NgComponentOutlet, DirectivesModule, NgClass, ReactiveFormsModule, IconComponent],
})
export class InputComponent
  extends BaseFormControlComponent<string | null>
  implements AfterViewInit, OnInit
{
  @ViewChild('inputElement') input?: ElementRef<HTMLInputElement | HTMLTextAreaElement>;

  readonly formControlConstants = FormControlConstants;

  @Input() type = 'text';

  @Input() autocomplete = 'on';

  @Input() textArea = false;

  @Input() fieldName = '';

  @Input() rows = 3;

  @Input() icon?: AuxIconName;

  @Input() iconPosition: 'left' | 'right' = 'right';

  @Input() iconClassName = '';

  @Input() labelClassName = '';

  @Input() showRequiredAsterisk = false;

  @Input() autofocus = false;

  @Input() min = '';

  @Input() max = '';

  @Input() trialTimelinePeriod?: DateControlPeriodParams;

  @Input() disabled = false;

  @Input() textareaAutoresize = false;

  @Input() noTriggerValueChangeOnBlur?: boolean;

  @Input() inputClassName = '';

  @Input() inAppBudgetStyle = false;

  @Input() showErrorMessage = true;

  @Input() errMessage = '';

  ignoreValidations = input(false);
  constructor(private overlayService: OverlayService) {
    super();
  }

  ngOnInit() {
    if (
      this.min === '' &&
      (this.type === 'date' || this.type === 'month') &&
      !this.ignoreValidations()
    ) {
      this.min = this.formControlConstants.MIN_VALUE.DATE;
    }
  }

  ngAfterViewInit() {
    // This solution is implemented in this way to focus element inside ag-grid cell
    if (this.autofocus) {
      setTimeout(() => {
        this.input?.nativeElement.focus();
      });
    }
  }

  handleMaxDate() {
    if (this.ignoreValidations()) {
      return;
    }

    if (this.type !== 'date' && this.type !== 'month') {
      return;
    }

    if (!dayjs(this.fc.value).isValid() && this.fc.value !== null) {
      this.fc.setValue(null);

      return;
    }

    if (this.fc.errors?.trialTimelinePeriod) {
      return;
    }

    if (
      this.trialTimelinePeriod?.endDate &&
      dayjs(this.fc.value).isAfter(this.trialTimelinePeriod?.endDate)
    ) {
      this.fc.setErrors({ trialTimelinePeriod: true });
      return;
    }

    if (dayjs(this.fc.value).isAfter(this.max)) {
      if (this.autofocus) {
        this.onChange(undefined);
        this.overlayService.error(this.formControlConstants.VALIDATION_MESSAGE.MONTH_MAX);
      } else {
        this.fc.setValue(null);
        this.fc.setErrors({ month_max: true });
      }
    }
  }

  handleMinDate() {
    if (this.ignoreValidations()) {
      return;
    }

    if (this.type !== 'date' && this.type !== 'month') {
      return;
    }

    if (!dayjs(this.fc.value).isValid() && this.fc.value !== null) {
      this.fc.setValue(null);

      return;
    }

    if (
      this.trialTimelinePeriod?.startDate &&
      dayjs(this.fc.value).isBefore(this.trialTimelinePeriod?.startDate)
    ) {
      this.fc.setErrors({ trialTimelinePeriod: true });
      return;
    }

    if (dayjs(this.fc.value).isBefore(this.min)) {
      if (this.autofocus) {
        if (this.type === 'date') {
          this.overlayService.error(this.formControlConstants.VALIDATION_MESSAGE.DATE);
        } else {
          this.overlayService.error(this.formControlConstants.VALIDATION_MESSAGE.MONTH_MIN);
        }
      } else {
        if (this.type === 'date') {
          this.fc.setErrors({ date: true });
        } else {
          this.fc.setErrors({ month_min: true });
        }
      }
    }
  }

  onKeyDown({ key }: KeyboardEvent) {
    if (key === 'Enter' || (key === 'Tab' && this.autofocus)) {
      this.handleMinDate();
      this.handleMaxDate();
    }
  }

  onBlur() {
    if (this.type === 'date') {
      if (!this.fc.pristine && this.fc.value) {
        this.fc.setValue(this.fc.value.trim());
      }
      this.handleMinDate();
      this.handleMaxDate();

      if (!this.noTriggerValueChangeOnBlur && !this.fc.errors) {
        super.onBlur();
      }
    } else {
      super.onBlur();
    }
  }
}
