import { Amplify } from '@aws-amplify/core';
import { StorageAction } from '@aws-amplify/core/internals/utils';
import { calculateContentMd5 } from '../../utils/md5.mjs';
import { resolveS3ConfigAndInput } from '../../utils/resolveS3ConfigAndInput.mjs';
import '../../../../errors/types/validation.mjs';
import '../../../../utils/logger.mjs';
import '../../utils/client/base.mjs';
import '../../utils/client/getObject.mjs';
import '../../utils/client/listObjectsV2.mjs';
import { putObject } from '../../utils/client/putObject.mjs';
import '../../utils/client/createMultipartUpload.mjs';
import '../../utils/client/uploadPart.mjs';
import '../../utils/client/completeMultipartUpload.mjs';
import '../../utils/client/listParts.mjs';
import '../../utils/client/abortMultipartUpload.mjs';
import '../../utils/client/copyObject.mjs';
import '../../utils/client/headObject.mjs';
import '../../utils/client/deleteObject.mjs';
import { getStorageUserAgentValue } from '../../utils/userAgent.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Get a function the returns a promise to call putObject API to S3.
 *
 * @internal
 */
const putObjectJob = ({ options: uploadDataOptions, key, data }, abortSignal, totalLength) => async () => {
    const { bucket, keyPrefix, s3Config, isObjectLockEnabled } = await resolveS3ConfigAndInput(Amplify, uploadDataOptions);
    const finalKey = keyPrefix + key;
    const { contentDisposition, contentEncoding, contentType = 'application/octet-stream', metadata, onProgress, } = uploadDataOptions ?? {};
    const { ETag: eTag, VersionId: versionId } = await putObject({
        ...s3Config,
        abortSignal,
        onUploadProgress: onProgress,
        userAgentValue: getStorageUserAgentValue(StorageAction.UploadData),
    }, {
        Bucket: bucket,
        Key: finalKey,
        Body: data,
        ContentType: contentType,
        ContentDisposition: contentDisposition,
        ContentEncoding: contentEncoding,
        Metadata: metadata,
        ContentMD5: isObjectLockEnabled
            ? await calculateContentMd5(data)
            : undefined,
    });
    return {
        key,
        eTag,
        versionId,
        contentType,
        metadata,
        size: totalLength,
    };
};

export { putObjectJob };

