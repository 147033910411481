import { StorageAction } from '@aws-amplify/core/internals/utils';
import '@smithy/md5-js';
import '@aws-amplify/core/internals/aws-client-utils';
import '../../utils/client/runtime/s3TransferHandler/fetch.mjs';
import 'fast-xml-parser';
import '../../utils/client/runtime/s3TransferHandler/xhr.mjs';
import 'buffer';
import { resolveS3ConfigAndInput } from '../../utils/resolveS3ConfigAndInput.mjs';
import '../../../../errors/types/validation.mjs';
import { logger } from '../../../../utils/logger.mjs';
import '../../utils/client/base.mjs';
import '../../utils/client/getObject.mjs';
import { listObjectsV2 } from '../../utils/client/listObjectsV2.mjs';
import '../../utils/client/putObject.mjs';
import '../../utils/client/createMultipartUpload.mjs';
import '../../utils/client/uploadPart.mjs';
import '../../utils/client/completeMultipartUpload.mjs';
import '../../utils/client/listParts.mjs';
import '../../utils/client/abortMultipartUpload.mjs';
import '../../utils/client/copyObject.mjs';
import '../../utils/client/headObject.mjs';
import '../../utils/client/deleteObject.mjs';
import { getStorageUserAgentValue } from '../../utils/userAgent.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const MAX_PAGE_SIZE = 1000;
const list = async (amplify, input) => {
    const { options = {}, prefix: path = '' } = input ?? {};
    const { s3Config, bucket, keyPrefix: prefix, } = await resolveS3ConfigAndInput(amplify, options);
    // @ts-expect-error pageSize and nextToken should not coexist with listAll
    if (options?.listAll && (options?.pageSize || options?.nextToken)) {
        const anyOptions = options;
        logger.debug(`listAll is set to true, ignoring ${anyOptions?.pageSize ? `pageSize: ${anyOptions?.pageSize}` : ''} ${anyOptions?.nextToken ? `nextToken: ${anyOptions?.nextToken}` : ''}.`);
    }
    const listParams = {
        Bucket: bucket,
        Prefix: `${prefix}${path}`,
        MaxKeys: options?.listAll ? undefined : options?.pageSize,
        ContinuationToken: options?.listAll ? undefined : options?.nextToken,
    };
    logger.debug(`listing items from "${listParams.Prefix}"`);
    return options.listAll
        ? _listAll({ s3Config, listParams, prefix })
        : _list({ s3Config, listParams, prefix });
};
const _listAll = async ({ s3Config, listParams, prefix, }) => {
    const listResult = [];
    let continuationToken = listParams.ContinuationToken;
    do {
        const { items: pageResults, nextToken: pageNextToken } = await _list({
            prefix,
            s3Config,
            listParams: {
                ...listParams,
                ContinuationToken: continuationToken,
                MaxKeys: MAX_PAGE_SIZE,
            },
        });
        listResult.push(...pageResults);
        continuationToken = pageNextToken;
    } while (continuationToken);
    return {
        items: listResult,
    };
};
const _list = async ({ s3Config, listParams, prefix, }) => {
    const listParamsClone = { ...listParams };
    if (!listParamsClone.MaxKeys || listParamsClone.MaxKeys > MAX_PAGE_SIZE) {
        logger.debug(`defaulting pageSize to ${MAX_PAGE_SIZE}.`);
        listParamsClone.MaxKeys = MAX_PAGE_SIZE;
    }
    const response = await listObjectsV2({
        ...s3Config,
        userAgentValue: getStorageUserAgentValue(StorageAction.List),
    }, listParamsClone);
    if (!response?.Contents) {
        return {
            items: [],
        };
    }
    const listResult = response.Contents.map(item => ({
        key: item.Key.substring(prefix.length),
        eTag: item.ETag,
        lastModified: item.LastModified,
        size: item.Size,
    }));
    return {
        items: listResult,
        nextToken: response.NextContinuationToken,
    };
};

export { list };

