import { combine, elapsed, generateUUID } from '@datadog/browser-core';
export function startVitalCollection(lifeCycle) {
    var vitalStartsByName = new Map();
    return {
        startDurationVital: function (vitalStart) {
            vitalStartsByName.set(vitalStart.name, vitalStart);
        },
        stopDurationVital: function (vitalStop) {
            var vitalStart = vitalStartsByName.get(vitalStop.name);
            if (!vitalStart) {
                return;
            }
            var vital = {
                name: vitalStart.name,
                type: "duration" /* VitalType.DURATION */,
                startClocks: vitalStart.startClocks,
                value: elapsed(vitalStart.startClocks.timeStamp, vitalStop.stopClocks.timeStamp),
                context: combine(vitalStart.context, vitalStop.context),
            };
            lifeCycle.notify(12 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, processVital(vital));
            vitalStartsByName.delete(vitalStop.name);
        },
    };
}
function processVital(vital) {
    var _a;
    return {
        rawRumEvent: {
            date: vital.startClocks.timeStamp,
            vital: {
                id: generateUUID(),
                type: vital.type,
                name: vital.name,
                custom: (_a = {},
                    _a[vital.name] = vital.value,
                    _a),
            },
            type: "vital" /* RumEventType.VITAL */,
        },
        startTime: vital.startClocks.relative,
        customerContext: vital.context,
        domainContext: {},
    };
}
