import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  getCurrentUser,
  signIn,
  signOut,
  confirmSignUp,
  fetchAuthSession,
  fetchUserAttributes,
} from '@aws-amplify/auth';
import { SignInOutput } from '@aws-amplify/auth/src/providers/cognito/types';
import { GqlService, PermissionType } from '@services/gql.service';
import { environment } from '../../../../environments/environment';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { AllowedRolesPermissions } from '@directives/authorize.directive';
import { sha256 as Sha256 } from 'sha.js';
import { firstValueFrom, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthState, AuthStore } from './auth.store';
import { LoggedInUser } from './LoggedInUser';
import { datadogRum } from '@datadog/browser-rum';
import { AppInitService } from '@services/app-init.service';
import { AuthQuery } from './auth.query';
import { LocalStorageKey } from '@shared/constants/localStorageKey';
import { Router } from '@angular/router';
import { ROUTING_PATH } from '@shared/constants/routingPath';

export const broadCastChannel = new BroadcastChannel('authentication');

@Injectable({ providedIn: 'root' })
export class AuthService {
  latestUserParams: undefined | { email: string; password: string };

  private tokenLookup = new Map<string, LoggedInUser>();

  private tokenPromiseLookup = new Map<string, Promise<LoggedInUser>>();

  constructor(
    private authStore: AuthStore,
    private authQuery: AuthQuery,
    private gqlService: GqlService,
    private ld: LaunchDarklyService,
    private appInitService: AppInitService,
    private router: Router
  ) {
    broadCastChannel.onmessage = (event) => {
      if (event.data === 'Logout') {
        this.router.navigate([`/${ROUTING_PATH.LOGIN}`], { queryParams: {} });
        window.location.reload();
      }
    };
  }

  async isAuthenticated() {
    let isAuthenticated = true;

    try {
      await getCurrentUser();
    } catch (err) {
      isAuthenticated = false;
    }

    return isAuthenticated;
  }

  async signIn(email: string, password: string): Promise<SignInOutput> {
    return signIn({ username: email, password });
  }

  async signOut() {
    localStorage.removeItem(LocalStorageKey.STAGE_BANNER);
    localStorage.removeItem(LocalStorageKey.LOGIN_LAST_URL);
    localStorage.removeItem(LocalStorageKey.LAST_ACTIVE_DATE);

    try {
      await signOut();
    } catch (e) {
      // UserAlreadyAuthenticatedException
      console.log(e);
    }

    this.authStore.reset();
    this.tokenLookup.clear();
    this.tokenPromiseLookup.clear();
    broadCastChannel.postMessage('Logout');
  }

  async confirmSignUp({ username, code }: { username: string; code: string }) {
    let result = true;
    try {
      const resp = await confirmSignUp({
        username,
        confirmationCode: code,
      });
      console.log('resp', resp);
    } catch (e) {
      result = false;
    }
    return result;
  }

  async setUserAttributes() {
    const userAttributes = await fetchUserAttributes();
    const session = await fetchAuthSession();
    const payload = session.tokens?.idToken?.payload;

    const attributes: AuthState = {
      sub: userAttributes.sub || '',
      given_name: userAttributes.given_name || '',
      family_name: userAttributes.family_name || '',
      email: userAttributes.email || '',
      is_admin: (<string[]>payload?.['cognito:groups'] || []).includes('Admin'),
      trial_id: <string>payload?.trial_id,
      permissions: [],
      roles: [],
      organization_id: null,
      department: [],
      title: '',
      is_sys_admin: false,
    };

    this.authStore.update(attributes);

    await this.ld.changeUser({
      key: attributes.sub,
      firstName: attributes.given_name,
      lastName: attributes.family_name,
      email: attributes.email,
      custom: {
        clientName: environment.launchDarkly.clientName,
      },
    });

    try {
      const loggedInUser = await this.getLoggedInUser();

      if (loggedInUser) {
        let role = loggedInUser.getRoles().indexOf('Admin') > -1 ? 'Admin' : 'Read Only';
        role = attributes.email.indexOf('@auxili.us') > -1 ? 'Auxilius Admin' : role;

        this.authStore.update({
          ...attributes,
          permissions: loggedInUser.getPermissions() as PermissionType[],
          roles: loggedInUser.getRoles(),
          organization_id: loggedInUser.getOrganizationId(),
          department: loggedInUser.getDepartment(),
          title: loggedInUser.getTitle(),
          is_sys_admin: loggedInUser.IsSysAdmin(),
        });

        window?.pendo?.initialize({
          visitor: {
            id: attributes.sub, // 'VISITOR-UNIQUE-ID', // Required if user is logged in
            email: attributes.email, // Recommended if using Pendo Feedback, or NPS Email
            full_name: `${attributes.given_name} ${attributes.family_name}`,
            role, // Optional
            departments: loggedInUser.getAllDepartments(),
            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },
          account: {
            id: environment.analytics.Pendo.accountId, // 'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback
            // name:         // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional
            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });

        datadogRum.init({
          applicationId: '62556293-3de9-4d37-a1e9-83d249e41bc7',
          clientToken: 'pub2625c0074cf36ade11978af0b16c4588',
          site: 'datadoghq.com',
          service: 'auxilius',
          env: environment.stage,
          version: this.appInitService.APP_VERSION,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackResources: true,
          trackLongTasks: true,
          trackUserInteractions: true,
          defaultPrivacyLevel: 'allow',
        });

        datadogRum.setUser({
          id: attributes.sub,
          name: `${attributes.given_name} ${attributes.family_name}`,
          email: attributes.email,
          role,
        });
      }
    } catch (e) {
      console.error(e);
    }

    return attributes;
  }

  async getUserSession() {
    try {
      return fetchAuthSession();
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  private getTokenPromise(k: string): Promise<LoggedInUser> {
    // this internal/helper method is for preventing concurrent requests to the backend
    // while the information about the same token is still being retrieved
    const promiseInProgress = this.tokenPromiseLookup.get(k);
    if (promiseInProgress) {
      return promiseInProgress;
    }
    // eslint-disable-next-line no-async-promise-executor
    const result = new Promise<LoggedInUser>(async (resolve, reject) => {
      try {
        const response = await firstValueFrom(this.gqlService.loggedInUser$());
        if (response.success) {
          resolve(new LoggedInUser(response.data));
        } else {
          reject(new Error(JSON.stringify(response.errors)));
        }
      } catch (e) {
        reject(e);
      }
    });
    this.tokenPromiseLookup.set(k, result);
    return result;
  }

  async getLoggedInUser() {
    try {
      const session = await this.getUserSession();
      const jwtToken = session?.tokens?.idToken?.toString();
      if (jwtToken) {
        const k = new Sha256().update(jwtToken).digest('hex');
        let loggedInUser = this.tokenLookup.get(k);
        if (!loggedInUser) {
          try {
            loggedInUser = await this.getTokenPromise(k);
          } finally {
            if (loggedInUser) {
              this.tokenLookup.set(k, loggedInUser);
            }
            this.tokenPromiseLookup.delete(k);
          }
        }
        return loggedInUser;
      }
    } catch (err) {
      console.log('Failed to get the logged in user', err);
    }
    return null;
  }

  isAuthorizedSync(user: LoggedInUser, allowedRolesPermissions: AllowedRolesPermissions) {
    if (user.IsSysAdmin()) {
      return true;
    }
    if (allowedRolesPermissions?.sysAdminsOnly) {
      return false;
    }
    const allowedRoles: Array<string> = [];
    if (Array.isArray(allowedRolesPermissions?.roles)) {
      allowedRolesPermissions.roles.forEach((r) => {
        if (r) {
          allowedRoles.push(r.toUpperCase());
        }
      });
    }
    if (user.hasRole(...allowedRoles)) {
      return true;
    }
    const allowedPermissions: Array<string> = [];
    if (Array.isArray(allowedRolesPermissions?.permissions)) {
      allowedRolesPermissions.permissions.forEach((p) => {
        if (p) {
          allowedPermissions.push(p.toUpperCase());
        }
      });
    }
    return user.hasPermission(...allowedPermissions);
  }

  isAuthorized$(allowedRolesPermissions: AllowedRolesPermissions): Observable<boolean> {
    return this.authQuery.select().pipe(
      map((user) => {
        return this.isAuthorizedSync(new LoggedInUser(user), allowedRolesPermissions);
      })
    );
  }

  $isAuthorized(allowedRolesPermissions: AllowedRolesPermissions) {
    return toSignal(this.isAuthorized$(allowedRolesPermissions), { requireSync: true });
  }

  updatePermissions$() {
    return this.gqlService.userPermissions$().pipe(
      tap(({ data, success }) => {
        if (data && success) {
          this.authStore.update({
            permissions: data.permissions,
          });
        }
      })
    );
  }
}
