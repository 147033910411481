import { Amplify } from '@aws-amplify/core';
import { remove as remove$1 } from './internal/remove.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Remove a file from your S3 bucket.
 * @param input - The RemoveInput object.
 * @return Output containing the removed object key
 * @throws service: {@link S3Exception} - S3 service errors thrown while getting properties
 * @throws validation: {@link StorageValidationErrorCode } - Validation errors thrown
 */
const remove = (input) => {
    return remove$1(Amplify, input);
};

export { remove };

