import { completeOAuthSignOut } from './completeOAuthSignOut.mjs';
import { oAuthSignOutRedirect } from './oAuthSignOutRedirect.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const handleOAuthSignOut = async (cognitoConfig, store) => {
    const { isOAuthSignIn } = await store.loadOAuthSignIn();
    // Clear everything before attempting to visted logout endpoint since the current application
    // state could be wiped away on redirect
    await completeOAuthSignOut(store);
    if (isOAuthSignIn) {
        // On web, this will always end up being a void action
        return oAuthSignOutRedirect(cognitoConfig);
    }
};

export { handleOAuthSignOut };

