import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export class DateUtils {
  static isInRange(date: string, range: { startDate?: string; endDate?: string }) {
    const parsedDate = dayjs(date);

    return parsedDate.isSameOrAfter(range.startDate) && parsedDate.isSameOrBefore(range.endDate);
  }
}
