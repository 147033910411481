import '../../../utils/client/base.mjs';
import '../../../utils/client/getObject.mjs';
import '../../../utils/client/listObjectsV2.mjs';
import '../../../utils/client/putObject.mjs';
import { createMultipartUpload } from '../../../utils/client/createMultipartUpload.mjs';
import '../../../utils/client/uploadPart.mjs';
import '../../../utils/client/completeMultipartUpload.mjs';
import '../../../utils/client/listParts.mjs';
import '../../../utils/client/abortMultipartUpload.mjs';
import '../../../utils/client/copyObject.mjs';
import '../../../utils/client/headObject.mjs';
import '../../../utils/client/deleteObject.mjs';
import '../../../../../errors/types/validation.mjs';
import '@aws-amplify/core/internals/utils';
import { logger } from '../../../../../utils/logger.mjs';
import { getUploadsCacheKey, findCachedUploadParts, cacheMultipartUpload } from './uploadCache.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Load the in-progress multipart upload from local storage or async storage(RN) if it exists, or create a new multipart
 * upload.
 *
 * @internal
 */
const loadOrCreateMultipartUpload = async ({ s3Config, data, size, contentType, bucket, accessLevel, keyPrefix, key, contentDisposition, contentEncoding, metadata, abortSignal, }) => {
    const finalKey = keyPrefix + key;
    let cachedUpload;
    if (size === undefined) {
        logger.debug('uploaded data size cannot be determined, skipping cache.');
        cachedUpload = undefined;
    }
    else {
        const uploadCacheKey = getUploadsCacheKey({
            size,
            contentType,
            file: data instanceof File ? data : undefined,
            bucket,
            accessLevel,
            key,
        });
        const cachedUploadParts = await findCachedUploadParts({
            s3Config,
            cacheKey: uploadCacheKey,
            bucket,
            finalKey,
        });
        cachedUpload = cachedUploadParts
            ? { ...cachedUploadParts, uploadCacheKey }
            : undefined;
    }
    if (cachedUpload) {
        return {
            uploadId: cachedUpload.uploadId,
            cachedParts: cachedUpload.parts,
        };
    }
    else {
        const { UploadId } = await createMultipartUpload({
            ...s3Config,
            abortSignal,
        }, {
            Bucket: bucket,
            Key: finalKey,
            ContentType: contentType,
            ContentDisposition: contentDisposition,
            ContentEncoding: contentEncoding,
            Metadata: metadata,
        });
        if (size === undefined) {
            logger.debug('uploaded data size cannot be determined, skipping cache.');
            return {
                uploadId: UploadId,
                cachedParts: [],
            };
        }
        const uploadCacheKey = getUploadsCacheKey({
            size,
            contentType,
            file: data instanceof File ? data : undefined,
            bucket,
            accessLevel,
            key,
        });
        await cacheMultipartUpload(uploadCacheKey, {
            uploadId: UploadId,
            bucket,
            key,
            fileName: data instanceof File ? data.name : '',
        });
        return {
            uploadId: UploadId,
            cachedParts: [],
        };
    }
};

export { loadOrCreateMultipartUpload };

