import { timeStampNow } from '@datadog/browser-core';
import { RecordType } from '../../../types';
export function initViewEndObserver(lifeCycle, viewEndCb) {
    return lifeCycle.subscribe(5 /* LifeCycleEventType.VIEW_ENDED */, function () {
        viewEndCb({
            timestamp: timeStampNow(),
            type: RecordType.ViewEnd,
        });
    }).unsubscribe;
}
