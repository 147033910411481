import { from, Observable, of, retry, throwError, timer } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ROUTING_PATH } from '@shared/constants/routingPath';

export const isGraphResponse = (obj: unknown): obj is GraphqlResponse<unknown> =>
  !!obj && typeof obj === 'object' && 'data' in obj;

export const isDuplicateError = (error: string, searchField?: string): boolean =>
  error.includes('duplicate') && (searchField ? error.includes(searchField) : true);

export const retryOn429 =
  <T extends { errors?: { message: string }[] }>() =>
  (attempts: T) => {
    const maxRetryAttempts = 3;
    const scalingDuration = 500;

    return of(attempts).pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;

        const is429Error = (error?.errors || [])
          .map((res) => res.message)
          .some((m) => m.includes('429'));
        if (is429Error && retryAttempt <= maxRetryAttempts) {
          return timer(retryAttempt * scalingDuration);
        }

        return throwError(() => error);
      })
    );
  };

export function gql$<T>(fn: Promise<T>, router: Router): Observable<GraphqlResponse<T>> {
  return from(fn).pipe(
    map((response) => {
      if (response) {
        return {
          success: true,
          data: response as T,
          errors: [] as string[],
        };
      }

      return {
        success: false,
        data: null,
        errors: [] as string[],
      };
    }),
    retry({ delay: retryOn429() }),
    catchError((err) => {
      const errors = err?.message
        ? [err.message]
        : ((err?.errors || []).map((res: { message: string }) => res.message) as string[]);

      if (
        errors.length &&
        errors.some((e) => e === 'No current user') &&
        window.location.pathname !== `/${ROUTING_PATH.LOGIN}`
      ) {
        (async () => {
          await router.navigate([`/${ROUTING_PATH.LOGIN}`], { queryParams: {} });
          window.location.reload();
        })();
      }

      return of({
        success: false,
        data: null,
        errors,
      });
    })
  );
}
