import { parseMetadata } from '@aws-amplify/core/internals/aws-client-utils';
import '../runtime/s3TransferHandler/fetch.mjs';
import { parser } from '../runtime/xmlParser/pureJs.mjs';
import '../runtime/s3TransferHandler/xhr.mjs';
import 'buffer';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const parseXmlError = async (response) => {
    if (!response || response.statusCode < 300) {
        return;
    }
    const { statusCode } = response;
    const body = await parseXmlBody(response);
    const code = body?.Code
        ? body.Code
        : statusCode === 404
            ? 'NotFound'
            : statusCode.toString();
    const message = body?.message ?? body?.Message ?? code;
    const error = new Error(message);
    return Object.assign(error, {
        name: code,
        $metadata: parseMetadata(response),
    });
};
const parseXmlBody = async (response) => {
    if (!response.body) {
        // S3 can return 200 without a body indicating failure.
        throw new Error('S3 aborted request.');
    }
    const data = await response.body.text();
    if (data?.length > 0) {
        try {
            return parser.parse(data);
        }
        catch (error) {
            throw new Error('Failed to parse XML response.');
        }
    }
    return {};
};

export { parseXmlBody, parseXmlError };

