import { AuxExcelStyleKeys } from './aux-excel-style-keys';
import { AuxExcelFormats } from './aux-excel-formats';
import { AuxExcelDefaultBorder } from './aux-excel-shared-styles';
import { ExcelInterior, ExcelStyle } from '@ag-grid-community/core';

export const greenAccentInterior: ExcelInterior = {
  patternColor: '#c7fff3',
  color: '#c7fff3',
  pattern: 'Solid',
};

const firstRowExcelStyle: Partial<ExcelStyle> = {
  font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
  alignment: { horizontal: 'Left' },
  interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
};

export const AuxExcelStyleMap: Record<AuxExcelStyleKeys, Partial<ExcelStyle>> = {
  [AuxExcelStyleKeys.TEXT_FORMAT]: {
    font: { fontName: 'Arial', size: 11 },
    dataType: 'String',
  },
  [AuxExcelStyleKeys.NUMBER]: {
    alignment: { horizontal: 'Right' },
    numberFormat: { format: AuxExcelFormats.Units },
  },
  [AuxExcelStyleKeys.CELL_LEFT]: {
    alignment: {
      horizontal: 'Left',
    },
  },
  [AuxExcelStyleKeys.CELL_RIGHT]: {
    alignment: {
      horizontal: 'Right',
    },
  },
  [AuxExcelStyleKeys.TOTAL_TEXT_FORMAT]: {
    font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
    interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
    dataType: 'String',
  },
  [AuxExcelStyleKeys.TOTAL_ROW_PERCENT]: {
    font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
    interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
    dataType: 'Number',
    numberFormat: { format: AuxExcelFormats.Percent },
  },
  [AuxExcelStyleKeys.TOTAL_ROW_AMOUNT]: {
    font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
    interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
    dataType: 'Number',
  },
  [AuxExcelStyleKeys.TOTAL_ROW_NO_CURRENCY_SYMBOL]: {
    font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
    interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
    dataType: 'Number',
    numberFormat: { format: AuxExcelFormats.Units },
  },
  [AuxExcelStyleKeys.TOTAL_ROW]: {
    font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
    interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
    dataType: 'Number',
    numberFormat: { format: AuxExcelFormats.Cost },
  },
  [AuxExcelStyleKeys.TOTAL_ROW_HEADER]: {
    font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
    interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
  },
  [AuxExcelStyleKeys.CELL]: {
    font: { fontName: 'Arial', size: 11 },
  },
  [AuxExcelStyleKeys.HEADER_GROUP]: {
    font: {
      fontName: 'Arial',
      size: 11,
      bold: true,
      color: '#FFFFFF',
    },
    interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
    alignment: { horizontal: 'Center' },
  },
  [AuxExcelStyleKeys.HEADER]: {
    font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
    interior: { patternColor: '#094673', color: '#094673', pattern: 'Solid' },
    alignment: { horizontal: 'Center' },
  },
  [AuxExcelStyleKeys.BUDGET_UNITS]: {
    alignment: { horizontal: 'Right' },
    numberFormat: { format: AuxExcelFormats.Units },
  },
  [AuxExcelStyleKeys.BUDGET_UNIT]: {
    dataType: 'Number',
    numberFormat: { format: '#;(#);—' },
  },
  [AuxExcelStyleKeys.FIRST_ROW]: firstRowExcelStyle,
  [AuxExcelStyleKeys.PERCENT]: {
    dataType: 'Number',
    numberFormat: { format: AuxExcelFormats.Percent },
  },
  [AuxExcelStyleKeys.COST]: {
    dataType: 'Number',
    numberFormat: { format: AuxExcelFormats.Cost },
  },
  [AuxExcelStyleKeys.BORDER_LEFT]: {
    borders: {
      borderLeft: AuxExcelDefaultBorder,
    },
  },
  [AuxExcelStyleKeys.BORDER_RIGHT]: {
    borders: {
      borderRight: AuxExcelDefaultBorder,
    },
  },
  [AuxExcelStyleKeys.BORDER_BOTTOM]: {
    borders: {
      borderBottom: AuxExcelDefaultBorder,
    },
  },
  [AuxExcelStyleKeys.BORDER_TOP]: {
    borders: {
      borderTop: AuxExcelDefaultBorder,
    },
  },
  [AuxExcelStyleKeys.ALTERNATE]: {
    interior: { patternColor: '#4F6D79', color: '#4F6D79', pattern: 'Solid' },
  },
  [AuxExcelStyleKeys.TEXT_AUX_ERROR]: {
    font: { color: '#D73C37' },
  },
  [AuxExcelStyleKeys.TEXT_AUX_GREEN]: {
    font: { color: '#437F7F' },
  },
  [AuxExcelStyleKeys.CELL_PERCENT]: {
    font: { fontName: 'Arial', size: 11 },
  },
  [AuxExcelStyleKeys.GREEN_ACCENT_FIRST_ROW]: {
    ...firstRowExcelStyle,
    font: {
      ...firstRowExcelStyle.font,
      color: '#000000',
    },
    interior: greenAccentInterior,
  },
};

export const AuxExcelStyles: ExcelStyle[] = Object.entries(AuxExcelStyleMap).map(([key, style]) => {
  return {
    id: key,
    ...style,
  };
});

export function GetExcelStyle(key: AuxExcelStyleKeys) {
  return <ExcelStyle>{
    id: key,
    ...AuxExcelStyleMap[key],
  };
}
