import { computed, Injectable } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Query } from '@datorama/akita';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { AuthStore, AuthState } from './auth.store';
import { PermissionType } from '@services/gql.service';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  isLoggedIn$ = this.select((user) => !!user.email);

  fullName$ = this.select((user) => `${user.given_name} ${user.family_name}`);

  name$ = this.select((user) => `${user.given_name}`);

  isUser$ = this.select((user) => !user.is_admin);

  user = toSignal(this.select(), { requireSync: true });

  adminUser = computed(() => {
    const { domains } = this.launchDarklyService.$select((flags) => flags.super_user)();
    if (domains) {
      return domains.some((domain) => this.user().email.includes(domain));
    }
    return this.user().email.includes('@auxili.us');
  });

  adminUser$ = toObservable(this.adminUser);

  getFullName() {
    const user = this.getValue();
    return `${user.given_name} ${user.family_name}`;
  }

  getEmail() {
    const user = this.getValue();
    return user.email;
  }

  constructor(
    protected store: AuthStore,
    protected launchDarklyService: LaunchDarklyService
  ) {
    super(store);
  }

  isSysAdmin() {
    return this.getValue().is_admin;
  }

  hasPermission(permission: PermissionType): boolean {
    const isAdmin = this.isSysAdmin();

    if (isAdmin) {
      return true;
    }

    const { permissions } = this.getValue();

    return permissions.includes(permission);
  }

  isAuxAdmin() {
    const domains = this.launchDarklyService.flags$.getValue().super_user.domains;
    if (domains) {
      return domains.some((domain) => this.getValue().email.includes(domain));
    }
    return this.getValue().email.includes('@auxili.us');
  }
}
