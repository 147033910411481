import { assertTokenProviderConfig, fetchAuthSession, AuthAction } from '@aws-amplify/core/internals/utils';
import { getUser } from '../../utils/clients/CognitoIdentityProvider/index.mjs';
import { getRegion } from '../../utils/clients/CognitoIdentityProvider/utils.mjs';
import { assertAuthTokens } from '../../utils/types.mjs';
import { toAuthUserAttribute } from '../../utils/apiHelpers.mjs';
import { getAuthUserAgentValue } from '../../../../utils/getAuthUserAgentValue.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const fetchUserAttributes = async (amplify) => {
    const authConfig = amplify.getConfig().Auth?.Cognito;
    assertTokenProviderConfig(authConfig);
    const { tokens } = await fetchAuthSession(amplify, {
        forceRefresh: false,
    });
    assertAuthTokens(tokens);
    const { UserAttributes } = await getUser({
        region: getRegion(authConfig.userPoolId),
        userAgentValue: getAuthUserAgentValue(AuthAction.FetchUserAttributes),
    }, {
        AccessToken: tokens.accessToken.toString(),
    });
    return toAuthUserAttribute(UserAttributes);
};

export { fetchUserAttributes };

