import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { File } from '@components/file-manager/state/file.model';
import { OverlayService } from '@services/overlay.service';
import { of } from 'rxjs';
import { FileManagerComponent } from '../file-manager.component';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { IconComponent } from '@components/icon/icon.component';

@Component({
  selector: 'aux-file-manager-uploaded-files',
  templateUrl: 'file-manager-uploaded-files.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgClass, IconComponent, NgIf, NgFor],
})
export class FileManagerUploadedFilesComponent implements OnInit, OnChanges {
  @Input() fileManager?: FileManagerComponent;

  @Input() titleLess = false;

  @Input() filesContainerClassNames = '';

  @Input() disabled = false;

  @Input() removeImmediately = true;

  @Output() removeFile = new EventEmitter<File>();

  classNames = '';

  uploadedFiles$ = of<File[]>([]);

  constructor(
    private overlayService: OverlayService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    Promise.resolve().then(() => {
      if (this.fileManager) {
        this.uploadedFiles$ = this.fileManager.fileQuery.selectAll();
        this.cdr.detectChanges();
      }
    });
  }

  ngOnChanges(): void {
    this.classNames = `mt-4 text-sm overflow-auto ${this.filesContainerClassNames}`;
  }

  async onRemoveFile(file: File) {
    if (this.disabled) {
      return;
    }

    if (this.removeImmediately) {
      const success = await this.fileManager?.removeFile(file);

      if (success) {
        this.removeFile.emit(file);
        this.overlayService.success(`${file.fileName} removed!`);
      }
    } else {
      this.fileManager?.removeFileSoft(file);
      this.removeFile.emit(file);
    }
  }
}
