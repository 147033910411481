import gql from 'fake-tag';
import {
  AnalyticsCardInput,
  EventType,
  getAnalyticsCardQuery,
  GqlService,
  NotificationPage,
  NotificationStatus,
  notificationSubscription,
} from '@services/gql.service';
import { Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, filter, map, startWith, switchMap } from 'rxjs/operators';
import { AuthQuery } from '@shared/store/auth/auth.query';
import { OverlayService } from '@services/overlay.service';
import { NotificationPages, SubscriptionEvents } from '@services/event.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { AuthService } from '@shared/store/auth/auth.service';
import { MainStore } from '@shared/store/main/main.store';
import { ApiService } from './api.service';
import { generateClient } from 'aws-amplify/api';
import { GraphqlSubscriptionResult } from '@aws-amplify/api-graphql/src/types';
import { createEtlTaskTracker$, EtlTaskStatus } from '@shared/utils/etl-task-tracker';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class EventService {
  private ignoreEventStatuses = [
    EventType.CLOSE_TRIAL_MONTH,
    EventType.CREATE_TRIAL_BUDGET_SNAPSHOT,
    EventType.CREATE_BUDGET_SNAPSHOT,
  ];

  events$ = new Subject<{
    type: EventType;
    data: SubscriptionEvents[NotificationStatus][EventType];
    page: NotificationPage;
    status: NotificationStatus;
  }>();

  select$<T extends keyof SubscriptionEvents[NotificationStatus.SUCCESS]>(type: T) {
    return this.events$.pipe(
      filter((event) => {
        return event.status === NotificationStatus.SUCCESS && event.type === type;
      }),
      map((x) => x.data)
    ) as Observable<SubscriptionEvents[NotificationStatus.SUCCESS][T]>;
  }

  selectAnalyticsCard$(input: AnalyticsCardInput) {
    return this.select$(EventType.ANALYTICS_CARD_UPDATED).pipe(
      startWith(null),
      filter((e) => {
        return e ? e.analyticsCardType === input.analytics_card_type : true;
      }),
      switchMap((data) => {
        if (data) {
          return of({
            success: true,
            errors: [] as string[],
            data: {
              data: data.analyticsCardData,
              primary_key: data.entity_id,
              sort_key: input.analytics_card_type,
              __typename: 'AnalyticsCard',
            } as getAnalyticsCardQuery,
          });
        }
        return this.gqlService.getAnalyticsCard$(input);
      })
    );
  }

  getEtlTaskTracker$(id: string): Observable<EtlTaskStatus> {
    return createEtlTaskTracker$(this.gqlService, id);
  }

  constructor(
    private authQuery: AuthQuery,
    private overlayService: OverlayService,
    private router: Router,
    private gqlService: GqlService,
    private authService: AuthService,
    private mainStore: MainStore,
    private apiService: ApiService
  ) {
    this.authQuery
      .select('sub')
      .pipe(
        switchMap((sub) => {
          if (sub) {
            return this.notificationListener$(sub);
          }

          return of(null);
        }),
        untilDestroyed(this)
      )
      .subscribe((value) => {
        if (value) {
          const { errors, data, success } = value;
          if (success && data) {
            this.events$.next({
              type: data.type,
              data: data.json,
              page: data.page,
              status: data.status,
            });
          } else if (errors) {
            this.overlayService.error(errors);
          }
        }
      });

    this.events$.pipe(untilDestroyed(this)).subscribe(async ({ type, data, page, status }) => {
      // region Event log
      console.group(`New Event ::: ${type}`);
      console.log(`DATA ::: `, data);
      console.log(`PAGE ::: ${page}`);
      console.log(`STATUS ::: ${status}`);
      console.log(`CURRENT URL ::: ${this.router.url.substring(0, this.router.url.indexOf('?'))}`);
      console.groupEnd();
      // endregion
      const { notification_message } =
        data as SubscriptionEvents[NotificationStatus.ERROR][typeof type];
      const { file_path } =
        data as SubscriptionEvents[NotificationStatus.EXPORT_READY][typeof type];
      if (
        notification_message &&
        !this.ignoreEventStatuses.includes(type) &&
        (page === NotificationPage.GLOBAL ||
          NotificationPages[page] === this.router.url.substring(0, this.router.url.indexOf('?')))
      ) {
        switch (status) {
          case NotificationStatus.SUCCESS:
            this.overlayService.success(notification_message);
            break;
          case NotificationStatus.ERROR:
            this.overlayService.error(notification_message, 6e5);
            break;
          case NotificationStatus.EXPORT_READY:
            if (file_path) {
              this.overlayService.success(notification_message);
              await this.apiService.downloadFileFromPath(file_path);
            } else {
              this.overlayService.error(notification_message, 6e5);
            }
            break;
          default:
            this.overlayService.success(notification_message);
            break;
        }
      }

      this.mainStore.setProcessingLoadingState(type, false);
    });
  }

  notificationListener$(sub: string): Observable<{
    success: boolean;
    errors: string[];
    data: {
      json: SubscriptionEvents[NotificationStatus][EventType];
      sub: string;
      type: EventType;
      page: NotificationPage;
      status: NotificationStatus;
    } | null;
  }> {
    const statement = gql`
      subscription notification($sub: String!) {
        notification(sub: $sub) {
          __typename
          data
          sub
          type
          status
          page
        }
      }
    `;
    const gqlAPIServiceArguments = {
      sub,
    };

    const client = generateClient();

    const ss = client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments,
    }) as unknown as GraphqlSubscriptionResult<{
      notification: notificationSubscription;
      errors: string[];
    }>;

    return ss.pipe(
      map((params) => {
        const rawData = params.data;
        const errors: string[] = rawData.errors;

        if (rawData) {
          const { type, data: strJson, page, status } = rawData.notification;
          let json;
          try {
            json = JSON.parse(strJson);
          } catch (e) {
            console.error(e);
            return {
              success: false,
              errors: ['json could not be parsed'],
              data: null,
            };
          }
          return {
            success: true,
            errors,
            data: { json, sub, type, page, status },
          };
        }
        return {
          success: true,
          errors,
          data: null,
        };
      }),
      catchError((err) => {
        return of({
          success: false,
          data: null,
          errors: (err.errors || []).map((res: { message: string }) => res.message) as string[],
        });
      })
    );
  }
}
