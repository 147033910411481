export const ROUTING_PATH = {
  TRIAL_INSIGHTS: {
    INDEX: 'trial-insights',
    CLINICAL: 'clinical',
    FINANCE: 'finance',
  },
  BUDGET: {
    INDEX: 'budget',
    LIBRARY: 'library',
    CHANGE_LOG: 'change-log',
    CHANGE_ORDER: 'change-orders',
    COMPARE: 'compare',
  },
  CLOSING: {
    INDEX: 'period-close',
    CHECKLIST: 'checklist',
    QUARTER_CLOSE: 'quarter-close',
    NOTES: 'notes',
    ADJUSTMENTS: 'adjustments',
    RECONCILIATION: 'reconciliation',
    JOURNAL_ENTRIES: 'journal-entries',
  },
  VENDOR_PAYMENTS: {
    INDEX: 'vendor-payments',
    INVOICES: 'invoices',
    PURCHASE_ORDERS: 'purchase-orders',
    PAYMENT_SCHEDULE: 'payment-schedule',
    VENDORS: 'vendors',
    PAYMENT_MILESTONES: 'payment-milestones',
  },
  FORECAST_ROUTING: {
    INDEX: 'forecast',
    INVESTIGATOR_FORECAST: 'investigator-forecast',
    FORECAST_METHODOLOGY: 'forecast-methodology',
    STUDY_SPECIFICATIONS: 'study-specifications',
    TIMELINE: {
      INDEX: 'timeline',
      TRIAL_TIMELINE: 'trial-timeline',
      PAYMENT_MILESTONES: 'payment-milestones',
    },
    PATIENT_DRIVER: {
      INDEX: 'patient-drivers',
      CURVES: 'patient-curves',
      GROUPS: 'patient-groups',
    },
    SITE_DRIVER: {
      INDEX: 'site-drivers',
      CURVES: 'site-curves',
      GROUPS: 'site-groups',
    },
  },
  MANAGER: 'scenario-manager',
  AUDIT_HISTORY: 'audit-history',
  DOCUMENTS: 'document-library',
  SETTINGS: {
    INDEX: 'settings',
    USERS: 'users',
    USER_PERMISSIONS: 'user-permissions',
    EXCHANGE_RATES: 'exchange-rates',
    INTEGRATIONS: 'integrations',
    EXPENSE_DEFAULTS: 'expense-defaults',
  },
  ONBOARDING: {
    INDEX: 'onboarding',
    VENDOR: 'vendor',
    USER: 'user',
    SITE: 'site',
    USER_PREFERENCES: 'user-preferences',
    PROCESSING: 'processing',
  },
  LOGIN: 'login',
  CONFIRMATION: 'confirmation',
  FORGOT_PASSWORD: 'forgot-password',
  HOME: 'home',
  ACCOUNT: {
    INDEX: 'account',
    SETTINGS: 'settings',
    NOTIFICATIONS: 'notifications',
  },
  DASHBOARD: 'dashboard',
  RISK_ANALYTICS: 'risk-analytics',
  DESIGN_SYSTEM: 'design-system',
  SYSTEM_MAINTENANCE: 'system-maintenance',
  INVESTIGATOR: {
    INDEX: 'investigator',
    INVESTIGATOR_SUMMARY: 'investigator-summary',
    INVESTIGATOR_TRANSACTIONS: 'investigator-transactions',
    PATIENT_TRACKER: 'patient-tracker',
    PATIENT_BUDGET: {
      INDEX: 'patient-budget',
    },
    PATIENT_BUDGET_ENTRY: 'patient-budget-entry',
    PATIENT_GROUPS: 'patient-groups',
    SITES: {
      INDEX: 'sites',
      INFO: 'info',
      SITE_BUDGET: 'sitebudget',
    },
    INVESTIGATOR_DETAIL: 'investigator-detail',
  },
  OPS_ADMIN: {
    INDEX: 'ops-admin',
    EDC_CONVERTER: {
      INDEX: 'edc-converter',
      LEGACY: 'legacy',
      NEW: 'new',
    },
    JE_GENERATOR: 'je-generator',
    EXTRACTOR: 'extractor',
    INVOICE_GENERATOR: 'invoice-generator',
  },
};
