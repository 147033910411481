export class MessagesConstants {
  static readonly PAGE_LOCKED_FOR_PERIOD_CLOSE = 'This Page is Locked for Period Close';

  static readonly LOCKED_FOR_PERIOD_CLOSE =
    'Locked for Period Close. Navigate to Period Close > Checklist and unlock to make changes.';

  static readonly VENDOR_ESTIMATES_LOCKED =
    'Vendor Estimates locked for current month close. Navigate to Period Close > Checklist and unlock to make changes.';

  static readonly VENDOR_EXPENSES_LOCKED =
    'Vendor Expenses locked for current month close. Navigate to Period Close > Checklist and unlock to make changes.';

  static readonly CHANGES_UNABLE_SINCE_MONTH_CLOSED =
    'Adjustments can only be made for the current open month.';

  static readonly PATIENT_TRACKER_CLOSED =
    'Investigator Data locked for Period Close. Unlock in Period Close > Checklist to make changes.';

  static readonly CANT_ADJUST_DISCOUNT = 'Discount cannot be manually adjusted';

  static readonly DO_NOT_HAVE_PERMISSIONS_TO_ACTION =
    'You do not have permission to perform this action. Please contact your system administrator if this has been restricted by mistake.';

  static readonly TIMELINE_MUST_BE_ENTERED = 'Timeline must be entered to enter Budget.';

  static readonly FORGOT_PASSWORD = {
    EMAIL_HAS_BEEN_SEND:
      'An email has been sent to supplied email address. Please fill in the fields by entering your new password along with the code sent.',
    PASSWORD_RESET_SUCCESSFUL: 'Password reset successful!',
  };

  static readonly SITE = {
    CURRENCY_WARNING: `We've noticed your site's currency has changed. Please update your site costs on both the Visit Costs and Invoiceables tabs.`,
  };

  static readonly SITE_CURVES = {
    SUCCESSFULLY_CREATED: 'Site Curve successfully created',
  };

  static readonly PATIENT_CURVES = {
    SUCCESSFULLY_CREATED: 'Patient Curve successfully created',
  };

  static readonly RESOLVE_TABLE_ERRORS = 'Please resolve the errors inside the table!';

  static readonly SUCCESSFULLY_SAVED = 'Successfully Saved';

  static readonly ERROR_OCCURRED = 'An error has occurred';

  static readonly SYSTEM_MAINTENANCE =
    'Auxilius is temporarily down. Our forecast indicates that we’ll be up and running again soon!';

  static readonly START_DATE_BEFORE_END = 'Start Date must be before End Date';

  static readonly INVOICE = {
    SUCCESSFULLY_UPDATED: 'Invoice successfully updated!',
    DOES_NOT_HAVE_FILE: `This invoice doesn't have file`,
    TOTAL_VALIDATION:
      'Sum of Services Total, Discount Total, Investigator Total and Pass-Through Total must be equal to Total Amount.',
    CATEGORY_IS_NOT_IN_BUDGET:
      'Invoice is categorized to a cost category that is not in the vendor’s budget.',
  };

  static readonly ONLY_NUMERIC_VALUES_ARE_SUPPORTED = 'Only numeric values are supported.';

  static readonly SOME_CHANGES_ARE_NOT_SAVED = 'Something went wrong. Some changes are not saved.';

  static readonly FILE = {
    PROBLEM_DOWNLOADING_TEMPLATE: 'There was a problem downloading the template',
    NEED_UPLOAD_FILE: 'You need to upload a file!',
    MAX_ONE_FILE: 'Maximum one file allowed!',
    ERROR_UPLOADING_FILE: 'There was an error uploading the file. Please try again',
    MUST_BE_CSV: 'File type must be a .csv!',
  };

  static readonly LOGOUT = 'You have been logged out!';

  static readonly DUPLICATE_NAMES = 'Duplicate names are not allowed';

  static readonly EMPTY_NAME = 'Empty names are not allowed';

  static readonly MUST_SELECT_VENDOR = 'Must select a vendor!';

  static readonly REFRESH_PORTFOLIO_DASHBOARD = 'Refreshing Portfolio data...';
}
