import { BoundedBuffer, display, canUseEventBridge, displayAlreadyInitializedError, willSyntheticsInjectRum, noop, timeStampNow, clocksNow, assign, } from '@datadog/browser-core';
import { validateAndBuildRumConfiguration, } from '../domain/configuration';
export function createPreStartStrategy(_a, getCommonContext, trackingConsentState, doStartRum) {
    var ignoreInitIfSyntheticsWillInjectRum = _a.ignoreInitIfSyntheticsWillInjectRum, startDeflateWorker = _a.startDeflateWorker;
    var bufferApiCalls = new BoundedBuffer();
    var firstStartViewCall;
    var deflateWorker;
    var cachedInitConfiguration;
    var cachedConfiguration;
    var trackingConsentStateSubscription = trackingConsentState.observable.subscribe(tryStartRum);
    function tryStartRum() {
        if (!cachedInitConfiguration || !cachedConfiguration || !trackingConsentState.isGranted()) {
            return;
        }
        trackingConsentStateSubscription.unsubscribe();
        var initialViewOptions;
        if (cachedConfiguration.trackViewsManually) {
            if (!firstStartViewCall) {
                return;
            }
            // An initial view is always created when starting RUM.
            // When tracking views automatically, any startView call before RUM start creates an extra
            // view.
            // When tracking views manually, we use the ViewOptions from the first startView call as the
            // initial view options, and we remove the actual startView call so we don't create an extra
            // view.
            bufferApiCalls.remove(firstStartViewCall.callback);
            initialViewOptions = firstStartViewCall.options;
        }
        var startRumResult = doStartRum(cachedInitConfiguration, cachedConfiguration, deflateWorker, initialViewOptions);
        bufferApiCalls.drain(startRumResult);
    }
    return {
        init: function (initConfiguration) {
            if (!initConfiguration) {
                display.error('Missing configuration');
                return;
            }
            var eventBridgeAvailable = canUseEventBridge();
            if (eventBridgeAvailable) {
                initConfiguration = overrideInitConfigurationForBridge(initConfiguration);
            }
            // Expose the initial configuration regardless of initialization success.
            cachedInitConfiguration = initConfiguration;
            if (cachedConfiguration) {
                displayAlreadyInitializedError('DD_RUM', initConfiguration);
                return;
            }
            // If we are in a Synthetics test configured to automatically inject a RUM instance, we want
            // to completely discard the customer application RUM instance by ignoring their init() call.
            // But, we should not ignore the init() call from the Synthetics-injected RUM instance, so the
            // internal `ignoreInitIfSyntheticsWillInjectRum` option is here to bypass this condition.
            if (ignoreInitIfSyntheticsWillInjectRum && willSyntheticsInjectRum()) {
                return;
            }
            var configuration = validateAndBuildRumConfiguration(initConfiguration);
            if (!configuration) {
                return;
            }
            if (!eventBridgeAvailable && !configuration.sessionStoreStrategyType) {
                display.warn('No storage available for session. We will not send any data.');
                return;
            }
            if (configuration.compressIntakeRequests && !eventBridgeAvailable && startDeflateWorker) {
                deflateWorker = startDeflateWorker(configuration, 'Datadog RUM', 
                // Worker initialization can fail asynchronously, especially in Firefox where even CSP
                // issues are reported asynchronously. For now, the SDK will continue its execution even if
                // data won't be sent to Datadog. We could improve this behavior in the future.
                noop);
                if (!deflateWorker) {
                    // `startDeflateWorker` should have logged an error message explaining the issue
                    return;
                }
            }
            cachedConfiguration = configuration;
            trackingConsentState.tryToInit(configuration.trackingConsent);
            tryStartRum();
        },
        get initConfiguration() {
            return cachedInitConfiguration;
        },
        getInternalContext: noop,
        stopSession: noop,
        addTiming: function (name, time) {
            if (time === void 0) { time = timeStampNow(); }
            bufferApiCalls.add(function (startRumResult) { return startRumResult.addTiming(name, time); });
        },
        startView: function (options, startClocks) {
            if (startClocks === void 0) { startClocks = clocksNow(); }
            var callback = function (startRumResult) {
                startRumResult.startView(options, startClocks);
            };
            bufferApiCalls.add(callback);
            if (!firstStartViewCall) {
                firstStartViewCall = { options: options, callback: callback };
                tryStartRum();
            }
        },
        addAction: function (action, commonContext) {
            if (commonContext === void 0) { commonContext = getCommonContext(); }
            bufferApiCalls.add(function (startRumResult) { return startRumResult.addAction(action, commonContext); });
        },
        addError: function (providedError, commonContext) {
            if (commonContext === void 0) { commonContext = getCommonContext(); }
            bufferApiCalls.add(function (startRumResult) { return startRumResult.addError(providedError, commonContext); });
        },
        addFeatureFlagEvaluation: function (key, value) {
            bufferApiCalls.add(function (startRumResult) { return startRumResult.addFeatureFlagEvaluation(key, value); });
        },
        startDurationVital: function (vitalStart) {
            bufferApiCalls.add(function (startRumResult) { return startRumResult.startDurationVital(vitalStart); });
        },
        stopDurationVital: function (vitalStart) {
            bufferApiCalls.add(function (startRumResult) { return startRumResult.stopDurationVital(vitalStart); });
        },
    };
}
function overrideInitConfigurationForBridge(initConfiguration) {
    return assign({}, initConfiguration, {
        applicationId: '00000000-aaaa-0000-aaaa-000000000000',
        clientToken: 'empty',
        sessionSampleRate: 100,
    });
}
