import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RegexpConstants } from '@constants/regexp.constants';

export class CustomValidators {
  static emailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const valid = RegexpConstants.EMAIL.test(control.value);

      return valid ? null : { email: true };
    };
  }

  static greaterThanZeroValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === '' || value === null || value === undefined) {
        return null;
      }

      const numberValue = Number(value);

      return isNaN(numberValue) || numberValue <= 0 ? { greaterThanZero: true } : null;
    };
  }

  static mustHaveLowercaseCharacter(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === '' || value === null || value === undefined) {
        return null;
      }

      const isValid = /[a-z]+/g.test(value);

      return isValid ? null : { mustHaveLowercaseChar: true };
    };
  }

  static mustHaveUppercaseCharacter(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === '' || value === null || value === undefined) {
        return null;
      }

      const isValid = /[A-Z]+/g.test(value);

      return isValid ? null : { mustHaveUppercaseChar: true };
    };
  }

  static mustHaveCharacter(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === '' || value === null || value === undefined) {
        return null;
      }

      const isValid = /[^a-z0-9A-Z]+/g.test(value);

      return isValid ? null : { mustHaveChar: true };
    };
  }

  static mustHaveNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === '' || value === null || value === undefined) {
        return null;
      }

      const isValid = /[0-9]+/g.test(value);

      return isValid ? null : { mustHaveNumberChar: true };
    };
  }

  static mustEqualWith(requiredValue: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === '' || value === null || value === undefined || !requiredValue) {
        return null;
      }

      const isValid = requiredValue === value;

      return isValid ? null : { mustByEqual: true };
    };
  }

  static valueNotInList<T>(list: T[] = [], validationErrorKey: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as T;

      return list.includes(value) ? { [validationErrorKey]: true } : null;
    };
  }
}
