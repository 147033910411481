import { Amplify } from '@aws-amplify/core';
import { list as list$1 } from './internal/list.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const list = (input) => {
    return list$1(Amplify, input ?? {});
};

export { list };

