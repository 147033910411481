export enum LocalStorageKey {
  STAGE_BANNER = 'stage_banner',
  IN_MONTH_ACTIVITIES_HEADER_COLLAPSED = 'in_month_activities_header_collapsed',
  LOGIN_LAST_URL = 'login_last_url',
  LOGIN_SSO_SUBMIT = 'login_sso_submit',
  EVENT_TRACKER = 'event_ids',
  USER_PERMISSION_STORAGE = 'user_permission_storage',
  LAST_ACTIVE_DATE = 'last_active_date',
  SEND_PASSWORD_UPDATED_EMAIL = 'send_password_updated_email',
  COLUMN_STATES = 'columnStates',
}
